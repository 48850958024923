import {
  Box,
  Button,
  FormLabel,
  Heading,
  Icon,
  Image,
  Input,
  Stack,
  Text,
  Link,
  useToast,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
// import Logo from "../../assets/images/website/eMedst Logo.svg";
import authBg from "../../assets/images/auth.png";
import { FaArrowLeft, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { POST } from "../../utilities/ApiProvider";
import { PinInput, PinInputField, HStack } from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "../../reducers/useReducers";

function Otp() {
  const toast = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const user = useSelector((state) => state.value);
  useEffect(() => {
    (async () => {
      let localEmail = localStorage.getItem("forgotemail");

      setEmail(localEmail);
      console.log("email", email);
    })();
  }, []);

  const verifyCode = async () => {
    setIsLoading(true);
    try {
      if (code === "") {
        toast({
          description: "Code is required!",
          duration: 2000,
          position: "top-right",
          status: "error",
        });
      } else {
        let res = await POST(`/users/verify-otp`, {
          email,
          otp: Number(code),
        });
        console.log("verification", res);
        if (res.success === true) {
          toast({
            description: res.message,
            duration: 2000,
            position: "top-right",
            status: "success",
          });
          navigate("/changepassword");
        } else {
          toast({
            description: res.message,
            duration: 2000,
            position: "top-right",
            status: "error",
          });
        }
      }
    } catch (err) {
      toast({
        description: err.message,
        duration: 2000,
        position: "top-right",
        status: "error",
      });
    }
    setIsLoading(false);
  };

  return (
    <Box
      flex={1}
      m="0 auto"
      mt={{ base: "70px", lg: "90px" }}
      maxW={{ base: "100%", lg: "500px" }}
    >
      <Link onClick={() => navigate(-1)} p={"0"}>
        <Button
          bgColor={"transparent"}
          _hover={{ bgColor: "transparent" }}
          _active={{ bgColor: "transparent" }}
          mb="20px"
          p={"0 !important"}
          fontWeight={"500"}
        >
          <Icon as={FaArrowLeft} mr="10px" /> Back
        </Button>
      </Link>
      <Text
        textAlign={useBreakpointValue({ base: "center", md: "left" })}
        fontFamily={"heading"}
        color={useColorModeValue("gray.800", "white")}
        textTransform={"uppercase"}
        fontWeight={700}
        fontSize={"32px"}
        my={8}
      >
        the little hub
      </Text>
      {/* <Image mb="40px" w="150px" src={Logo} /> */}
      <Heading fontWeight={"500"} color={"black"} fontSize={"36px"} pb="20px">
        Verification
      </Heading>
      <Text fontWeight={"500"} color={"gray.500"} fontSize={"14px"} pb="20px">
        Enter code that we sent you on your email
      </Text>

      <FormLabel fontSize={"15px"} fontWeight={"400"} color="#784FCF!important">
        Code
      </FormLabel>

      <HStack>
        <PinInput value={code} onChange={(e) => setCode(e)} placeholder="-">
          <PinInputField
            width={"90px"}
            height={"60px"}
            border={"1px solid #803CCF"}
          />
          <PinInputField
            width={"90px"}
            height={"60px"}
            border={"1px solid #803CCF"}
          />
          <PinInputField
            width={"90px"}
            height={"60px"}
            border={"1px solid #803CCF"}
          />
          <PinInputField
            width={"90px"}
            height={"60px"}
            border={"1px solid #803CCF"}
          />
          <PinInputField
            width={"90px"}
            height={"60px"}
            border={"1px solid #803CCF"}
          />
        </PinInput>
      </HStack>
      <Box display={"flex"} w="100%" justifyContent={"flex-end"} mt={"15px"}>
        <Link
          as={ReactLink}
          to="/forgot-password"
          color={"primaryGreen.200"}
          borderBottom={"1px solid"}
          borderColor={"primaryGreen.200"}
          fontSize={"14px"}
        >
          Resend code
        </Link>
      </Box>
      <Button
        onClick={verifyCode}
        isLoading={isLoading}
        my="20px"
        bg={"#784FCF"}
        fontSize={"14px"}
        fontWeight={500}
        color={"#fff"}
        borderRadius={"10px"}
        w="100%"
        h="45px"
        textTransform={"uppercase"}
        border={"2px solid transparent"}
        _hover={{
          bgGradient: "none",
          borderColor: "primaryGreen.200",
          color: "primaryGreen.200",
        }}
      >
        Verify
      </Button>
    </Box>
  );
}

export default Otp;
