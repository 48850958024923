
import React, { useEffect, useState } from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Stack,
  Button,
  useToast,
} from "@chakra-ui/react";
import CustHeading from "../../components/Dashboard/Heading/CustHeading";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { GET, PATCH } from "../../utilities/ApiProvider";

export default function UpdatePrivacyPolicy() {
  const toast = useToast();

  const [Privacy, setPrivacy] = useState("");
  const [Terms, setTerms] = useState("");

  // --------------------GET CALLS---------------------------------

  const getPrivacy = async () => {
    try {
      const response = await GET("/pages/privacy");
      console.log(response);
      if (response?.statusCode === 200) {
        setPrivacy(response?.data?.content);
      } else {
        setPrivacy([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTerms = async () => {
    try {
      const response = await GET("/pages/terms");
      console.log(response);
      if (response?.statusCode === 200) {
        setTerms(response?.data?.content);
      } else {
        setTerms([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // --------------------GET CALLS END---------------------------------

  const handleTermsChange = (value) => {
    console.log("New Terms:", value);
    setTerms(value);
  };

  const handlePrivacyChange = (value) => {
    console.log("New Privacy Policy:", value);
    setPrivacy(value);
  };
  // ------------------------------HELPER-----------------------------------//
  const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  // ------------------------TERMS UPDATE----------------------------------------
  const saveTermsContent = async () => {
    const strippedContent = stripHtmlTags(Terms || "");
    if (!strippedContent.trim()) {
      toast({
        title: "Please enter some content",
        status: "error",
        duration: 3000,
        position: "top-right",
        isClosable: true,
      });
      // alert("Please enter some content");
      return;
    }
    try {
      const response = await PATCH("/pages/terms", {
        content: Terms,
        title: "terms",
      });
      console.log(response);
      if (response?.statusCode === 200) {
        setTerms(response?.data?.content);
        toast({
          description: response?.message,
          status: "success",
          isClosable: true,
          position: "top-right",
          duration: 3000,
        });
      } else {
        setTerms([]);
      }
    } catch (error) {
      console.log(error);
    }

    console.log("Saving Terms Content:", Terms);
    // Save Terms data here
  };
  //-----------------------------------UPDATE PRIVACY
  const savePrivacyContent = async () => {
    const strippedContent = stripHtmlTags(Privacy || "");
    if (!strippedContent.trim()) {
      toast({
        title: "Please enter some content",
        status: "error",
        duration: 3000,
        position: "top-right",
        isClosable: true,
      });
      // alert("Please enter some content");
      return;
    }
    try {
      const response = await PATCH("/pages/privacy", {
        content: Privacy,
        title: "terms",
      });
      console.log(response);
      if (response?.statusCode === 200) {
        setPrivacy(response?.data?.content);
        toast({
          description: response?.message,
          status: "success",
          isClosable: true,
          position: "top-right",
          duration: 3000,
        });
      } else {
        setPrivacy([]);
      }
    } catch (error) {
      console.log(error);
    }
    console.log("Saving Privacy Content:", Privacy);
    // Save Privacy Policy data here
  };

  useEffect(() => {
    getTerms();
    getPrivacy();
  }, []);

  console.log(Terms, "terms");
  return (
    <>
      <Tabs>
        <TabList width={"max-content"}>
          <Tab>Privacy Policy</Tab>
          <Tab>Terms of Service</Tab>
        </TabList>

        <TabPanels>
          <TabPanel pl={0}>
            <Stack gap={6}>
              <CustHeading
                name={"Privacy Policy"}
                subHeading={
                  "Here is the details of privacy policy...Check Now!"
                }
              />
              <ReactQuill
                theme="snow"
                value={Privacy}
                onChange={handlePrivacyChange}
                style={{ height: "300px" }}
              />

              <Button
                bg={"#784FCF"}
                color={"#fff"}
                padding={"22px 25px"}
                w={"max-content"}
                fontSize={"15px"}
                borderRadius={"10px"}
                mt={10}
                onClick={savePrivacyContent} // Save Privacy Policy content
              >
                Save Content
              </Button>
            </Stack>
          </TabPanel>
          <TabPanel pl={0}>
            <Stack gap={6}>
              <CustHeading
                name={"Terms of Service"}
                subHeading={
                  "Here is the details of Terms of Service...Check Now!"
                }
              />
              <ReactQuill
                theme="snow"
                value={Terms}
                onChange={handleTermsChange}
                style={{ height: "300px", color: "#5C5C5C" }}
              />
              {/* color={"#5C5C5C"} */}
              <Button
                bg={"#784FCF"}
                color={"#fff"}
                padding={"22px 25px"}
                w={"max-content"}
                fontSize={"15px"}
                borderRadius={"10px"}
                mt={10}
                onClick={saveTermsContent} // Save Terms content
              >
                Save Content
              </Button>
            </Stack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
}
