import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Button,
  FormControl,
  FormLabel,
  Stack,
  Input,
  SimpleGrid,
  useToast,
  Icon,
  Image,
  Text,
  Heading,
  Checkbox,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import CustHeading from "../../components/Dashboard/Heading/CustHeading";
import React, { useEffect, useRef, useState } from "react";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { GET, POST, PUT } from "../../utilities/ApiProvider";
import defaultPic from "../../assets/images/defaultPic.svg";
import { imgUrl } from "../../utilities/config";
import DayCares from "./DayCares";

export default function Account() {
  const toast = useToast();
  const imgReg = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [showpass, setshowpass] = useState(false);
  const [reshowpass, setreshowpass] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [newpass, setnewpass] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  // States for user information
  const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    email: "",
    number: "",
  });

  // Store the initial values
  const [initialValues, setInitialValues] = useState({});

  const [socialLinks, setSocialLinks] = useState({
    facebook: { url: "", isChecked: false },
    twitter: { url: "", isChecked: false },
    linkedin: { url: "", isChecked: false },
    instagram: { url: "", isChecked: false },
    skype: { url: "", isChecked: false },
    youtube: { url: "", isChecked: false },
  });

  const getAllDayCares = async () => {
    try {
      const response = await GET("/websetting");
      console.log("website setting", response);
      if (response?.success === true) {
        setData(response?.data?.logo);
        const updatedLinks = { ...socialLinks };

        response?.data?.socialLinks.forEach((link) => {
          updatedLinks[link.title.toLowerCase()] = {
            url: link.url,
            isChecked: link.isChecked === "true", // assuming isChecked comes as a string "true"/"false"
          };
        });

        setSocialLinks(updatedLinks);
      } else {
        setSocialLinks({
          facebook: { url: "", isChecked: false },
          twitter: { url: "", isChecked: false },
          linkedin: { url: "", isChecked: false },
          instagram: { url: "", isChecked: false },
          skype: { url: "", isChecked: false },
          youtube: { url: "", isChecked: false },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllDayCares();
  }, []);

  console.log("website 2", data);

  useEffect(() => {
    const localEmail = JSON.parse(localStorage.getItem("dayCareUser"));
    if (localEmail) {
      setUser({
        firstname: localEmail.firstname || "",
        lastname: localEmail.lastname || "",
        email: localEmail.email || "",
        number: localEmail.number || "",
      });
      setInitialValues({
        firstname: localEmail.firstname || "",
        lastname: localEmail.lastname || "",
        email: localEmail.email || "",
        number: localEmail.number || "",
      });
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();

    // Compare current values with initial values and add only changed fields to formData
    Object.keys(user).forEach((key) => {
      if (user[key] !== initialValues[key]) {
        formData.append(key, user[key]);
      }
    });

    // If no fields have changed, show a warning and return early
    if (Array.from(formData.entries()).length === 0) {
      toast({
        description: "No changes detected.",
        status: "warning",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
      setLoading(false);
      return;
    }

    try {
      const response = await POST("/users/update-account", formData);
      setLoading(false);

      if (response.success === true) {
        localStorage.setItem("dayCareUser", JSON.stringify(response?.data));
        toast({
          title: "Account updated successfully",
          status: "success",
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
        setInitialValues(user); // Update initial values to reflect the latest changes
      } else {
        toast({
          title: response.message,
          status: "error",
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const uploadProfilePic = async () => {
    try {
      let form = document.getElementById("profilePicForm");
      let data = new FormData(form);
      let response = await POST("/websetting", data);
      if (response.success === true) {
        setData(response?.data?.logo);
      } else {
        toast({
          description: response?.message,
          status: "error",
          isClosable: true,
          position: "top-right",
          duration: 3000,
        });
      }
      console.log("upload pic ", response);
    } catch (err) {
      toast({
        description: err.message,
        status: "error",
        position: "top-right",
        duration: 2500,
      });
    }
  };

  const handleSubmiticons = async (e) => {
    e.preventDefault();
    setLoading(true);

    const selectedLinks = Object.entries(socialLinks)
      .map(([key, value]) => ({
        title: key.charAt(0).toUpperCase() + key.slice(1),
        url: value.url,
        isChecked: value.isChecked,
      }))
      .filter((link) => link.isChecked && link.url);

    if (selectedLinks.length === 0) {
      setLoading(false);
      toast({
        description:
          "Please fill at least one social link and check the checkbox.",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await POST("/websetting/social", {
        socialLinks: selectedLinks,
      });

      if (response.success) {
        setLoading(false);
        toast({
          title: "Social links sent successfully",
          status: "success",
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
      } else {
        setLoading(false);
        toast({
          title: response.message,
          status: "error",
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast({
        title: "An error occurred",
        status: "error",
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const Getemail = JSON.parse(localStorage.getItem("dayCareUser"));
    const localEmail = Getemail.email;
    setnewpass((prev) => ({
      ...prev,
      email: localEmail,
    }));
  }, []);

  const validatePassword = () => {
    if (newpass.password.length < 8) {
      toast({
        description: "Password must be at least 8 characters long",
        status: "error",
        position: "top-right",
        duration: 3000,
      });
      return false;
    }

    if (newpass.password !== newpass.confirmPassword) {
      toast({
        description: "Passwords do not match",
        status: "error",
        position: "top-right",
        duration: 3000,
      });
      return false;
    }

    return true;
  };

  const EmpSubmit = async () => {
    if (!validatePassword()) return;

    setIsLoading(true);
    try {
      let res = await POST(`/users/change-password`, newpass);

      if (res.success === true) {
        onClose();
        toast({
          description: res.message,
          status: "success",
          position: "top-right",
          duration: 3000,
        });
      }
    } catch (err) {
      toast({
        description: err.message,
        status: "error",
        position: "top-right",
        duration: 3000,
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      <Tabs>
        <TabList width={"max-content"}>
          <Tab>My Account</Tab>
          <Tab>Web Settings</Tab>
        </TabList>
        <TabPanels>
          <TabPanel pl={0}>
            <Stack gap={6}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <CustHeading
                  name={"My Account"}
                  subHeading={"Update your account details"}
                />
                <Button
                  onClick={onOpen}
                  bg={"#784FCF"}
                  fontSize={{ base: "12px", xl: "13px" }}
                  fontWeight={500}
                  color={"#fff"}
                  width={"max-content"}
                  px={"22px"}
                  py={"20px"}
                  gap={2}
                  transition={".3s"}
                  h={{ base: "35px", xl: "45px" }}
                  _hover={{
                    boxShadow: "0px 7px 20px 0px #0000003b",
                  }}
                >
                  Change Password
                </Button>
              </Stack>
              <Stack>
                <form className="accset" onSubmit={handleSubmit}>
                  <Stack gap={6}>
                    <SimpleGrid columns={2} spacing={6}>
                      <Box>
                        <FormControl>
                          <FormLabel fontSize={"15px"}>First Name</FormLabel>
                          <Input
                            value={user.firstname}
                            onChange={(e) =>
                              setUser({ ...user, firstname: e.target.value })
                            }
                            py={6}
                            border={"1px solid #784FCF"}
                            name="firstname"
                            type="text"
                          />
                        </FormControl>
                      </Box>
                      <Box>
                        <FormControl>
                          <FormLabel fontSize={"15px"}>Last Name</FormLabel>
                          <Input
                            value={user.lastname}
                            onChange={(e) =>
                              setUser({ ...user, lastname: e.target.value })
                            }
                            py={6}
                            border={"1px solid #784FCF"}
                            name="lastname"
                            type="text"
                          />
                        </FormControl>
                      </Box>
                      <Box>
                        <FormControl>
                          <FormLabel fontSize={"15px"}>Email Address</FormLabel>
                          <Input
                            disabled
                            readOnly
                            value={user.email}
                            onChange={(e) =>
                              setUser({ ...user, email: e.target.value })
                            }
                            py={6}
                            border={"1px solid #784FCF"}
                            name="email"
                            type="email"
                          />
                        </FormControl>
                      </Box>
                      <Box>
                        <FormControl>
                          <FormLabel fontSize={"15px"}>Phone No.</FormLabel>
                          <Input
                            disabled
                            readOnly
                            value={user.number}
                            onChange={(e) =>
                              setUser({ ...user, number: e.target.value })
                            }
                            py={6}
                            border={"1px solid #784FCF"}
                            name="number"
                            type="number"
                          />
                        </FormControl>
                      </Box>
                    </SimpleGrid>

                    <Button
                      _hover={{
                        bg: "#784FCF",
                      }}
                      type="submit"
                      bg={"#784FCF"}
                      color={"#fff"}
                      py={"25px"}
                      px={"50px"}
                      width={"max-content"}
                      isLoading={loading}
                    >
                      Submit
                    </Button>
                  </Stack>
                </form>
              </Stack>
            </Stack>
          </TabPanel>
          <TabPanel pl={0}>
            <Stack gap={6}>
              <CustHeading
                name={"Web Settings"}
                subHeading={
                  "Enter the details to add new day care or you can import csv file"
                }
              />
              <Stack direction="row" alignItems="end" gap={6}>
                <Stack>
                  <Text fontWeight={500} fontSize={"13px"}>
                    Update Logo
                  </Text>
                  <Box
                    w="250px"
                    h="80px"
                    borderRadius={8}
                    border={"2px solid"}
                    borderColor="#784FCF"
                    overflow="hidden"
                  >
                    <Image
                      src={`${imgUrl}${data}` ?? defaultPic}
                      w="100%"
                      h="100%"
                      objectFit={"contain"}
                    />
                  </Box>
                </Stack>

                <Box>
                  <form id="profilePicForm">
                    <Input
                      onChange={uploadProfilePic}
                      ref={imgReg}
                      display={"none"}
                      type="file"
                      name="image"
                    />
                  </form>
                  <Stack className="btn">
                    <Button
                      isLoading={uploadingImage}
                      onClick={() => imgReg?.current.click()}
                      bg={"#784FCF"}
                      fontSize={{ base: "12px", xl: "13px" }}
                      fontWeight={500}
                      color={"#fff"}
                      width={"max-content"}
                      px={"22px"}
                      py={"20px"}
                      gap={2}
                      transition={".3s"}
                      h={{ base: "35px", xl: "45px" }}
                      _hover={{
                        boxShadow: "0px 7px 20px 0px #0000003b",
                      }}
                    >
                      <Text as={"span"}>Upload Photo</Text>
                    </Button>
                  </Stack>
                </Box>
              </Stack>
              <Stack>
                <Heading fontWeight={600} fontSize={"22px"}>
                  Update Social Links
                </Heading>
                <Stack>
                  <form onSubmit={handleSubmiticons}>
                    <Stack gap={6} w={"70%"}>
                      <SimpleGrid columns={2} spacing={6}>
                        {Object.keys(socialLinks).map((key) => (
                          <Box key={key}>
                            <FormControl>
                              <FormLabel fontSize={"15px"}>
                                {key.charAt(0).toUpperCase() + key.slice(1)}
                              </FormLabel>
                              <Box
                                display={"flex"}
                                alignItems={"center"}
                                gap={2}
                              >
                                <Input
                                  border={"1px solid #784FCF"}
                                  name={key}
                                  type="text"
                                  value={socialLinks[key].url}
                                  onChange={(e) =>
                                    setSocialLinks({
                                      ...socialLinks,
                                      [key]: {
                                        ...socialLinks[key],
                                        url: e.target.value,
                                      },
                                    })
                                  }
                                />
                                <Checkbox
                                  name={`${key}Check`}
                                  borderColor={"#784FCF"}
                                  colorScheme="green"
                                  size="lg"
                                  isChecked={socialLinks[key].isChecked}
                                  onChange={(e) =>
                                    setSocialLinks({
                                      ...socialLinks,
                                      [key]: {
                                        ...socialLinks[key],
                                        isChecked: e.target.checked,
                                      },
                                    })
                                  }
                                />
                              </Box>
                            </FormControl>
                          </Box>
                        ))}
                      </SimpleGrid>

                      <Button
                        _hover={{ bg: "#784FCF" }}
                        type="submit"
                        bg={"#784FCF"}
                        color={"#fff"}
                        py={"25px"}
                        px={"50px"}
                        width={"max-content"}
                        isLoading={loading}
                      >
                        Submit
                      </Button>
                    </Stack>
                  </form>
                </Stack>
              </Stack>
            </Stack>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change Password</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box className="applyForm">
              <Box>
                <FormLabel>Password*</FormLabel>
                <Box position={"relative"}>
                  <Input
                    type={showpass ? "text" : "password"}
                    value={newpass.password}
                    onChange={(e) =>
                      setnewpass({ ...newpass, password: e.target.value })
                    }
                    placeholder="Enter Password"
                  />
                  <Button
                    w={"40px"}
                    position={"absolute"}
                    top={"50%"}
                    transform={"translateY(-50%)"}
                    right={"0"}
                    bgColor={"transparent"}
                    _hover={{ bgColor: "transparent" }}
                    _active={{ bgColor: "transparent" }}
                    zIndex={1}
                    onClick={() => setshowpass(!showpass)}
                  >
                    <Icon
                      color={"#949494"}
                      as={showpass ? BsEye : BsEyeSlash}
                    />
                  </Button>
                </Box>
              </Box>
              <Box>
                <FormLabel>Confirm Password*</FormLabel>
                <Box position={"relative"}>
                  <Input
                    type={reshowpass ? "text" : "password"}
                    value={newpass.confirmPassword}
                    onChange={(e) =>
                      setnewpass({
                        ...newpass,
                        confirmPassword: e.target.value,
                      })
                    }
                    placeholder="Enter Re-type Password"
                  />
                  <Button
                    w={"40px"}
                    position={"absolute"}
                    top={"50%"}
                    transform={"translateY(-50%)"}
                    right={"0"}
                    bgColor={"transparent"}
                    _hover={{ bgColor: "transparent" }}
                    _active={{ bgColor: "transparent" }}
                    zIndex={1}
                    onClick={() => setreshowpass(!reshowpass)}
                  >
                    <Icon
                      color={"#949494"}
                      as={reshowpass ? BsEye : BsEyeSlash}
                    />
                  </Button>
                </Box>
              </Box>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              isLoading={isLoading}
              onClick={EmpSubmit}
              bg={"#784FCF"}
              fontSize={{ base: "12px", xl: "13px" }}
              fontWeight={500}
              color={"#fff"}
              width={"max-content"}
              px={"22px"}
              py={"20px"}
              gap={2}
              transition={".3s"}
              h={{ base: "35px", xl: "45px" }}
              _hover={{
                boxShadow: "0px 7px 20px 0px #0000003b",
              }}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
