import {
  Box,
  Button,
  FormLabel,
  Heading,
  Icon,
  Image,
  Input,
  Stack,
  Text,
  Link,
  useToast,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
// import Logo from "../../assets/images/website/eMedst Logo.svg";
import Logo from "../../assets/images/logo.png";
import { FaArrowLeft, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import { useState } from "react";
import { POST } from "../../utilities/ApiProvider";
import { useDispatch } from "react-redux";
import { loadUser } from "../../reducers/useReducers";

export default function Forget() {
  const toast = useToast();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const form = new FormData(e.target);

      const response = await POST("/users/send-otp", form);
      if (response.success === true) {
        localStorage.setItem("forgotemail", response?.data?.email);
        localStorage.setItem("daycareuser", JSON.stringify(response?.data));
        dispatch(loadUser(response?.data));

        toast({
          description: response?.message,
          status: "success",
          isClosable: true,
          position: "top-right",
          duration: 3000,
        });
        navigate("/verification");
      } else {
        toast({
          description: response?.message,
          status: "error",
          isClosable: true,
          position: "top-right",
          duration: 3000,
        });
      }
    } catch (err) {
      toast({
        description: err?.message,
        status: "error",
        isClosable: true,
        position: "top-right",
        duration: 3000,
      });
    }
    setIsLoading(false);
  };

  return (
    <Stack
      direction={{ base: "column", lg: "row" }}
      height={"100vh"}
      spacing={0}
    >
      <Box
        display={{ base: "none", lg: "block" }}
        bgImage={`url(${Logo})`}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
        // w={"605px"}
        w={{ lg: "405px", xl: "505px", xxl: "605px" }}
      >
        <Image src={Logo} w="100%" h="100%" />
        {/* Left Side Background Image */}
      </Box>
      <Box
        flex={1}
        m="0 auto"
        p={"0"}
        mt={{ base: "70px", lg: "100px" }}
        // p={{
        //   base: "60px 20px",
        //   md: "10px 40px",
        //   lg: "10px 60px",
        //   xl: "10px 60px",
        //   xxl: "0",
        // }}
        maxW={{ base: "100%", lg: "600px" }}
        // m="auto"
      >
        <Link onClick={() => navigate(-1)} p={"0"}>
          <Button
            bgColor={"transparent"}
            _hover={{ bgColor: "transparent" }}
            _active={{ bgColor: "transparent" }}
            mb="20px"
            fontWeight={"500"}
            p={"0 !important"}
          >
            <Icon as={FaArrowLeft} mr="10px" /> Back
          </Button>
        </Link>
        <Text
          textAlign={useBreakpointValue({ base: "center", md: "left" })}
          fontFamily={"heading"}
          color={useColorModeValue("gray.800", "white")}
          textTransform={"uppercase"}
          fontWeight={700}
          fontSize={"32px"}
          my={8}
        >
          the little hub
        </Text>
        {/* <Image mb="40px" w="150px" src={Logo} /> */}
        <Heading fontWeight={"500"} color={"black"} fontSize={"32px"} pb="20px">
          Login to your account
        </Heading>
        <Text fontWeight={"500"} color={"gray.500"} fontSize={"14px"} pb="20px">
          Enter your email to forgot
        </Text>
        <form className="applyForm" onSubmit={handleSubmit}>
          <Box mb="10px">
            <FormLabel>Email Address*</FormLabel>
            <Input name="email" placeholder="Enter here" required />
          </Box>

          <Button
            isLoading={isLoading}
            my="20px"
            bg="purple.500"
            fontSize={"14px"}
            fontWeight={500}
            color={"#fff"}
            borderRadius={"10px"}
            w="100%"
            h="45px"
            textTransform={"uppercase"}
            _hover={{
              bg: "purple.600",
            }}
            type="submit"
          >
            Continue
          </Button>
        </form>
      </Box>
    </Stack>
  );
}
