import React, { useEffect, useState } from "react";
import CustHeading from "../../components/Dashboard/Heading/CustHeading";
import {
  Box,
  Divider,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
  Button,
} from "@chakra-ui/react";
import { IoAnalytics } from "react-icons/io5";
import { GET } from "../../utilities/ApiProvider";
import Loader from "../../components/Dashboard/Loader/Loader";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { IoArrowBackSharp } from "react-icons/io5";
import { m } from "framer-motion";

export default function ViewLogs() {
  const [data, setData] = useState("");
  const navigate = useNavigate();

  const getAllDayCares = async () => {
    try {
      const response = await GET("/logs");
      console.log("logs", response);
      if (response?.success === true) {
        setData(response?.data.logs);
      } else {
        setData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllDayCares();
  }, []);

  return (
    <Stack>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <CustHeading
          name={"View Logs"}
          subHeading={"Here is the daycare’s list...Check Now!"}
        />
        <Button
          onClick={() => navigate("/dashboard")}
          color={"#fff"}
          bg={"#2E8B44"}
          w={"max-content"}
          textTransform={"uppercase"}
          fontSize={"14px"}
          _hover={{
            bg: "#2E8B44",
          }}
        >
          <Icon mr={2} fontSize={"20px"} as={IoArrowBackSharp} /> Back
        </Button>
      </Stack>

      {!data ? (
        <Loader />
      ) : (
        <Stack gap={6}>
          {data?.length > 0 &&
            data.map((v, k) => {
              return (
                <Stack
                  key={k}
                  px={6}
                  py={6}
                  bg={"#EBEBEB"}
                  borderRadius={8}
                  direction={"row"}
                  gap={16}
                  alignItems={"end"}
                >
                  <Flex gap={4} alignItems={"center"}>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      bg={"#784FCF"}
                      w={"50px"}
                      height={"50px"}
                      borderRadius={"50%"}
                    >
                      <Icon color={"#fff"} fontSize={"19px"} as={IoAnalytics} />
                    </Box>

                    <Stack gap={1}>
                      <Heading noOfLines={1} fontSize={"16px"}>
                        {v?.title}
                      </Heading>
                      <Text noOfLines={1} width={"150px"} fontSize={"14px"}>
                        {v?.description}
                      </Text>
                    </Stack>
                  </Flex>
                  <Divider bg={"#000"} width={"2px"} orientation="vertical" />
                  <Stack gap={1}>
                    <Text fontSize={"14px"}>Date:</Text>
                    <Heading fontSize={"16px"}>
                      {new Date(v?.createdAt).toLocaleDateString()}
                    </Heading>
                  </Stack>
                  <Stack gap={1}>
                    <Text fontSize={"14px"}>Time:</Text>
                    <Heading fontSize={"16px"}>
                      {moment(v?.createdAt).format("hh:mm A")}
                    </Heading>
                  </Stack>
                </Stack>
              );
            })}
        </Stack>
      )}
    </Stack>
  );
}
