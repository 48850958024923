import {
  Box,
  Button,
  FormLabel,
  Heading,
  Icon,
  Image,
  Input,
  Stack,
  Text,
  Link,
  useToast,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
// import Logo from "../../assets/images/website/eMedst Logo.svg";
import authBg from "../../assets/images/auth.png";
import { FaArrowLeft, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import { useState } from "react";
import { POST } from "../../utilities/ApiProvider";
import { useDispatch } from "react-redux";
import { loadUser } from "../../reducers/useReducers";
import Logo from "../../assets/images/logo.png";

export default function Login() {
  const toast = useToast();
  const [showpass, setshowpass] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const form = new FormData(e.target);

      const response = await POST("/users/login", form);
      console.log(response);
      if (response?.statusCode === 200) {
        localStorage.setItem(
          "dayCareUser",
          JSON.stringify(response?.data?.user)
        );
        dispatch(loadUser(response?.data?.user));
        navigate("/dashboard");
        toast({
          description: "Login successfully!",
          status: "success",
          isClosable: true,
          position: "top-right",
          duration: 3000,
        });
      } else {
        toast({
          description: response?.message,
          status: "error",
          isClosable: true,
          position: "top-right",
          duration: 3000,
        });
      }
    } catch (err) {
      toast({
        description: err?.message,
        status: "error",
        isClosable: true,
        position: "top-right",
        duration: 3000,
      });
    }
    setIsLoading(false);
  };

  return (
    <Stack
      direction={{ base: "column", lg: "row" }}
      height={"100vh"}
      spacing={0}
    >
      <Box
        display={{ base: "none", lg: "block" }}
        bgImage={`url(${Logo})`}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
        // flex={0.4}
        // w={"605px"}
        w={{ lg: "450px", xl: "550px", xxl: "605px" }}
      >
        <Image src={Logo} w="100%" h="100%" />
        {/* Left Side Background Image */}
      </Box>
      <Box
        flex={1}
        p={{
          base: "60px 20px",
          md: "19px 40px",
          lg: "19px 60px",
          xl: "19px 60px",
        }}
        maxW={{ base: "100%", lg: "600px" }}
        m="auto"
      >
        <Link as={ReactLink} to="/" p={"0"}>
          <Button
            bgColor={"transparent"}
            _hover={{ bgColor: "transparent" }}
            _active={{ bgColor: "transparent" }}
            mb="20px"
            p={"0 !important"}
            fontWeight={"500"}
          >
            <Icon as={FaArrowLeft} mr="10px" /> Back
          </Button>
        </Link>
        <Text
          textAlign={useBreakpointValue({ base: "center", md: "left" })}
          fontFamily={"heading"}
          color={useColorModeValue("gray.800", "white")}
          textTransform={"uppercase"}
          fontWeight={700}
          fontSize={"32px"}
          my={8}
        >
          the little hub
        </Text>
        {/* <Image mb="40px" w="150px" src={Logo} /> */}
        <Heading fontWeight={"500"} color={"black"} fontSize={"32px"} pb="20px">
          Login to your account
        </Heading>
        <Text fontWeight={"500"} color={"gray.500"} fontSize={"14px"} pb="20px">
          Enter your email and password to login
        </Text>
        <form className="applyForm" onSubmit={handleSubmit}>
          <Box mb="10px">
            <FormLabel>Email Address*</FormLabel>
            <Input name="email" placeholder="Enter here" required />
          </Box>
          <Box mb="10px">
            <FormLabel>Password*</FormLabel>
            <Box position={"relative"}>
              <Input
                required
                name="password"
                type={showpass ? "text" : "password"}
                placeholder="Enter here"
              />
              <Button
                w={"40px"}
                position={"absolute"}
                top={"50%"}
                transform={"translateY(-50%)"}
                right={"0"}
                bgColor={"transparent"}
                _hover={{ bgColor: "transparent" }}
                _active={{ bgColor: "transparent" }}
                zIndex={1}
                onClick={() => setshowpass(!showpass)}
              >
                <Icon as={showpass ? BsEye : BsEyeSlash} />
              </Button>
            </Box>
          </Box>
          <Box display={"flex"} w="100%" justifyContent={"flex-end"}>
            <Link
              as={ReactLink}
              to="/forgot-password"
              color={"blue.500"}
              borderColor={"blue.500"}
              fontSize={"14px"}
              __hover={{ borderBottom: "1px solid" }}
            >
              Forgot Password?
            </Link>
          </Box>
          <Button
            isLoading={isLoading}
            my="20px"
            bg="purple.500"
            fontSize={"14px"}
            fontWeight={500}
            color={"#fff"}
            borderRadius={"10px"}
            w="100%"
            h="45px"
            textTransform={"uppercase"}
            _hover={{
              bg: "purple.600",
            }}
            type="submit"
          >
            Log in
          </Button>
        </form>
      </Box>
    </Stack>
  );
}
