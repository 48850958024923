import React, { useState } from "react";
import { Stack } from "@chakra-ui/react";

import DashNavbar from "../components/Dashboard/DashNav/DashNavbar";
import { Outlet } from "react-router-dom";

function DashboardLayout() {
  return (
    <Stack px={4} bg={"#EBEBEB"}>
      <DashNavbar />
      <Stack
        overflowY="auto"
        margin={"0 !important"}
        gap={8}
        borderRadius={"30px 30px 0 0"}
        py={10}
        px={6}
        bg={"#fff"}
        height={"calc(100vh - 74px)"}
        bgPos={"-32px -100px"}
      >
        <Outlet />
      </Stack>
    </Stack>
  );
}

export default DashboardLayout;
