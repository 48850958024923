import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select"; // Importing react-select
import {
  Box,
  HStack,
  Heading,
  Text,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  FormControl,
  Input,
  Icon,
  AspectRatio,
  Checkbox,
  CheckboxGroup,
  Stack,
  Button,
  SimpleGrid,
  Container,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  FormLabel,
  ModalFooter,
  position,
  useToast,
  InputGroup,
  InputRightElement,
  Spinner,
} from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";
import DayCareMap from "./daycaremap";
import { GET } from "../../../utilities/ApiProvider";
import { useNavigate } from "react-router-dom";
import { PiCornersOutLight } from "react-icons/pi";
import { debounce } from "lodash";

export default function MapFilter() {
  const [selectedMarker, setSelectedMarker] = React.useState(null);

  const dummyAddresses = [
    {
      id: 1,
      formatted_address: "1600 Amphitheatre Parkway, Mountain View, CA",
      geometry: {
        location: {
          lat: 37.42216,
          lng: 10.08427,
        },
      },
    },
    {
      id: 2,
      formatted_address: "1 Infinite Loop, Cupertino, CA",
      geometry: {
        location: {
          lat: 37.33182,
          lng: -122.03118,
        },
      },
    },
    {
      id: 3,
      formatted_address: "Times Square, New York, NY",
      geometry: {
        location: {
          lat: 40.758896,
          lng: -73.98513,
        },
      },
    },
  ];
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();
  const toast = useToast();
  const [dayCareOptions, setDayCareOptions] = useState({});
  const [selectedAgeGroup, setSelectedAgeGroup] = useState([]);
  const [selectedDayCareType, setSelectedDayCareType] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isLoading, setIsLoading] = useState(false);

  const getAllDayCares = async (filter = "") => {
    setIsLoading(true);
    try {
      const response = await GET(`/daycare/search${filter}`);
      console.log("API Response:", response);

      if (response?.statusCode === 200) {
        setIsLoading(false);
        setData(response?.data?.dayCares || []);
        setTotalPages(response?.data?.pagination?.totalPages || 1);
        if (response?.data?.dayCares?.length === 0) {
          toast({
            title: "No Day Cares Found",
            description: "Please try with different filters",
            status: "success",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          });
        }
        onClose();
      } else {
        setIsLoading(false);

        console.log("No data found");
        setData([]);
        onClose();
      }
    } catch (error) {
      setIsLoading(false);

      onClose();

      console.log("API Error:", error);
    }
  };
  const daycareOptions = async () => {
    try {
      const response = await GET(`/daycare/helper`);
      console.log("API Response:", response);
      if (response?.statusCode === 200) {
        setDayCareOptions(response?.data);
      } else {
        setDayCareOptions({});
      }
    } catch (error) {
      console.log("API Error:", error);
    }
  };
  useEffect(() => {
    getAllDayCares();
    daycareOptions();
  }, []);

  // const handleChangeAgeGroup = (e) => {
  //   if (e.target.checked) {
  //     setSelectedAgeGroup((prevState) => [...prevState, e.target.value]);
  //   } else {
  //     setSelectedAgeGroup((prevState) =>
  //       prevState.filter((item) => item !== e.target.value)
  //     );
  //   }
  // };

  // const handleChangeDayCareType = (e) => {
  //   if (e.target.checked) {
  //     setSelectedDayCareType((prevState) => [...prevState, e.target.value]);
  //   } else {
  //     setSelectedDayCareType((prevState) =>
  //       prevState.filter((item) => item !== e.target.value)
  //     );
  //   }
  // };

  const handleChangeAgeGroup = (selectedOptions) => {
    const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setSelectedAgeGroup(values);
  };

  const handleChangeDayCareType = (selectedOptions) => {
    const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setSelectedDayCareType(values);
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    const params = new URLSearchParams();

    if (selectedAgeGroup.length > 0) {
      params.append("ageGroups", JSON.stringify(selectedAgeGroup));
    }

    if (selectedDayCareType.length > 0) {
      params.append("dayCareTypes", JSON.stringify(selectedDayCareType));
    }

    const queryString = params.toString() ? `?${params.toString()}` : "";
    console.log(queryString);
    getAllDayCares(queryString);
  };
  const clearFilter = () => {
    setSelectedAgeGroup([]);
    setSelectedDayCareType([]);
    getAllDayCares();
    setSearchQuery("");
  };
  const debouncedSearch = useCallback(
    debounce((query) => {
      const filter = query ? `?name=${query}` : "";
      getAllDayCares(filter);
    }, 500),
    []
  );
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    debouncedSearch(query);
  };


  

  return (
    <>
      <Stack
        pt={{
          base: 4,
          md: 4,
          xl: 2,
          "2xl": 8,
        }}
        id="daycares"
        className="map-sec mb-40 pb-20"
        px={{
          base: "20px",
          sm: "20px",
          md: "20px",
          lg: "20px",
          xl: "20px",
          "2xl": "20px",
        }}
      >
        <Container py={4} maxW={{ base: "8xl", xl: "6xl", "2xl": "8xl" }} >
          <Stack gap={8}>
            <Modal size={"2xl"} isOpen={isOpen} onClose={onClose} isCentered>
              <ModalOverlay />
              <ModalContent borderRadius={"24px"} p={"20px"}>
                <ModalCloseButton />
                <ModalBody>
                  <Stack>
                    <Stack>
                      <Text fontSize={"22px"} fontWeight={700}>Filters</Text>
                    </Stack>
                    <form onSubmit={handleSubmit}>
                      <Box mb="10px">
                        <FormLabel fontSize={"18px"} fontWeight={500}>Age Group:</FormLabel>
                        <Select
                          isMulti
                          options={dayCareOptions?.["ageGroup"]?.map(item => ({
                            value: item?._id,
                            label: item?.title,
                          }))}
                          onChange={handleChangeAgeGroup}
                          value={dayCareOptions?.["ageGroup"]?.map(item => ({
                            value: item?._id,
                            label: item?.title,
                          })).filter(option => selectedAgeGroup.includes(option.value))}
                          placeholder="Select Age Groups"
                        />
                      </Box>
                      <Box mb="10px">
                        <FormLabel fontSize={"18px"} fontWeight={500}>Day Care Types:</FormLabel>
                        <Select
                          isMulti
                          options={dayCareOptions?.["dayCareType"]?.map(item => ({
                            value: item?._id,
                            label: item?.title,
                          }))}
                          onChange={handleChangeDayCareType}
                          value={dayCareOptions?.["dayCareType"]?.map(item => ({
                            value: item?._id,
                            label: item?.title,
                          })).filter(option => selectedDayCareType.includes(option.value))}
                          placeholder="Select Day Care Types"
                        />
                      </Box>
                      <Stack>
                        <Button type="submit" colorScheme="purple" mr={3}>Save Filter</Button>
                      </Stack>
                    </form>
                  </Stack>
                </ModalBody>
              </ModalContent>
            </Modal>
            <Stack>
              <Text className="purple fs-20 t-center semi-bold source">
                Day Care’s
              </Text>
              <Heading
                textAlign={"center"}
                fontSize={{
                  base: "25px",
                  md: "35px",
                  xl: "35px",
                  "2xl": "35px",
                }}
              >
                Nearby Day Care’s
              </Heading>
            </Stack>
            {/* <Stack>
              <Stack>
                <Stack
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  gap={6}
                >
                  <InputGroup>
                    <Input
                      placeholder="Search here..."
                      value={searchQuery}
                      onChange={handleSearchChange}
                      disabled={isLoading}
                    />
                    {isLoading && (
                      <InputRightElement>
                        <Spinner size="sm" />
                      </InputRightElement>
                    )}
                  </InputGroup>

                  <Button disabled={isLoading} onClick={onOpen} isTruncated>
                    Filter
                  </Button>
                  <Button disabled={isLoading} onClick={clearFilter} isTruncated>
                    Clear Filter
                  </Button>
                </Stack>
              </Stack>
            </Stack> */}
            <Stack spacing={4} w="100%">
              <Stack>
                <Stack
                  flexDirection={{ base: "column", md: "row" }}
                  justifyContent="space-between"
                  alignItems={{ base: "stretch", md: "center" }}
                  gap={{ base: 4, md: 6 }}
                >
                  <InputGroup>
                    <Input
                      placeholder="Search here..."
                      value={searchQuery}
                      onChange={handleSearchChange}
                      disabled={isLoading}
                    />
                    {isLoading && (
                      <InputRightElement>
                        <Spinner size="sm" />
                      </InputRightElement>
                    )}
                  </InputGroup>
                  <Stack
                    flexDirection={{ base: "row", md: "row" }}
                    justifyContent="space-between"
                    alignItems={{ base: "stretch", md: "center" }}
                    gap={{ base: 4, md: 6 }}
                  >
                    <Button
                      disabled={isLoading}
                      onClick={onOpen}
                      w={{ base: "50%", md: "auto" }}
                      isTruncated
                    >
                      Filter
                    </Button>
                    <Button
                      disabled={isLoading}
                      onClick={clearFilter}
                      w={{ base: "50%", md: "auto" }}
                      isTruncated
                    >
                      Clear Filter
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Container>
        <Stack>
          <>{data && <DayCareMap data={data} />}</>
        </Stack>
      </Stack>
    </>
  );
}
