import React, { createContext } from "react";
import { Routes, Route, Outlet, Navigate, useLocation } from "react-router-dom";
import NotFound from "../views/NotFound";
import Home from "../views/Website/Home";
import Checking from "../components/Website/Map/checking";
import Login from "../views/auth/Login";
import Forget from "../views/auth/Forget";
import Verification from "../views/Verification";
import DashboardLayout from "../layout/DashboardLayout";
import DashboardHome from "../views/Dashboard/DashboardHome";
import DayCares from "../views/Dashboard/DayCares";
import AddNewDayCare from "../views/Dashboard/AddNewDayCare";
import PrivacyPolicy from "../views/Website/PrivacyPolicy";
import TermsServices from "../views/Website/TermsServices";
import DaycareDetails from "../views/Dashboard/DaycareDetails";
import ViewLogs from "../views/Dashboard/ViewLogs";
import Faqs from "../views/Dashboard/Faqs";
import ContactQueries from "../views/Dashboard/ContactQueries";
import UpdatePrivacyPolicy from "../views/Dashboard/PrivacyPolicy";
import ChangePassword from "../views/Verification/ChangePassword";
import SingleContactDetails from "../views/Dashboard/SingleContactDetails";
import Account from "../views/Dashboard/Account";

import Testing from "../components/Website/Map/Testing";

import AboutDetails from "../views/Website/AboutDetails";
import FaqsDetails from "../views/Website/FaqsDetails";


export const UserContext = createContext();

// Function to protect routes that require authentication
function RequireAuth() {
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem("dayCareUser") || "{}");

  if (user?.verificationToken) {
    return <Outlet />; // Allow access to the protected route
  } else {
    return <Navigate to="/login" state={{ from: location }} replace />; // Redirect to login if not authenticated
  }
}

// Function to protect routes that should only be accessible to non-authenticated users
function RequireNonAuth() {
  const user = JSON.parse(localStorage.getItem("dayCareUser") || "{}");

  if (user?.verificationToken) {
    return <Navigate to="/dashboard" replace />; // Redirect to dashboard if already authenticated
  } else {
    return <Outlet />; // Allow access to the non-authenticated route
  }
}

export default function AppRoute() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/checking" element={<Checking />} />

      <Route exact path="/testing" element={<Testing />} />

      <Route exact path="/AboutDetails" element={<AboutDetails />} />
      <Route exact path="/Faqs" element={<FaqsDetails />} />
      <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy />} />
      <Route exact path="/TermsServices" element={<TermsServices />} />
      <Route exact path="*" element={<NotFound />} />

      {/* Non-authenticated routes */}
      <Route element={<RequireNonAuth />}>
        <Route exact path="login" element={<Login />} />
        <Route exact path="forgot-password" element={<Forget />} />
        <Route exact path="verification" element={<Verification />} />
        <Route exact path="changepassword" element={<ChangePassword />} />
      </Route>

      {/* Authenticated routes */}
      <Route element={<RequireAuth />}>
        <Route path="/dashboard" element={<DashboardLayout />}>
          <Route index element={<DashboardHome />} />
          <Route path="daycares" element={<DayCares />} />
          <Route path="add-new-daycare" element={<AddNewDayCare />} />
          <Route path="daycaresDetail/:id" element={<DaycareDetails />} />
          <Route path="ViewLogs" element={<ViewLogs />} />
          <Route path="faqs" element={<Faqs />} />
          <Route path="Contact-Queries" element={<ContactQueries />} />
          <Route
            path="Contact-Details/:id"
            element={<SingleContactDetails />}
          />
          <Route
            path="update-Privacy-Policy"
            element={<UpdatePrivacyPolicy />}
          />
          <Route path="account" element={<Account />} />
        </Route>
      </Route>
    </Routes>
  );
}
