import {
  Container,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
  Button,
} from "@chakra-ui/react";
import React from "react";
import faqleft from "../../../assets/images/faqs.png";
import { Link } from "react-router-dom";

export default function Faqs({ faqs }) {
  console.log("fff", faqs);

  return (
    <>
      <Stack id="faqs" py={{
          base: 12,
          md: 12,
          xl: 12,
          "2xl": 20,
        }}>
        <Container py={4} maxW={{ base: "8xl", xl: "6xl", "2xl": "8xl" }}>
          <Stack pb={20} textAlign={"center"} gap={6}>
            <Heading as={"h4"} fontSize={"20px"} color={"#784FCF"}>
              FAQ’s
            </Heading>
            <Heading
              as={"h2"}
              fontSize={{ base: "25px", md: "30px", xl: "35px", "2xl": "35px" }}
              color={"#111"}
            >
              Frequently Ask Questions
            </Heading>
          </Stack>
          <SimpleGrid
            alignItems={"center"}
            columns={{ base: 1, md: 2, xl: 2 }}
            spacing={10}
          >
            <Stack>
              <Image src={faqleft} alt="" />
            </Stack>
            <Stack>
              <Accordion defaultIndex={[0]} allowMultiple>
                {faqs?.length > 0 &&
                  faqs.map((v, k) => {
                    return (
                      <AccordionItem  key={k} mb={2} border={"none"}>
                        <h2>
                          <AccordionButton
                          boxShadow='base'
                          mb={2}
                          py={4}
                            bg={"#f2f9f5"}
                            borderRadius={8}
                            _expanded={{ bg: "#784FCF", color: "white" }}
                          >
                            <Box as="span" flex="1" textAlign="left">
                              <Heading fontSize={"16px"} noOfLines={2}>
                                {v?.question}
                              </Heading>
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel p={0}      pb={4}>
                          <Text borderRadius={6} py={4} px={6} boxShadow='base'  fontSize={'15px'} fontWeight={500}> {v?.answer}</Text>
                        </AccordionPanel>
                      </AccordionItem>
                    );
                  })}
              </Accordion>
              <Button
                as={Link}
                to={'/Faqs'}
                 margin={{base:'auto', md:'0', xl:'0'}}
                  fontSize={"sm"}
                  fontWeight={600}
                  color={"white"}
                  width={"max-content"}
                  padding={"14px 40px"}
                  bg={"#784FCF"}
                  href={"#contactus"}
                  _hover={{
                    bg: "#784FCF",
                  }}
                >
                  View More Details
                </Button>
            </Stack>
          </SimpleGrid>
        </Container>
      </Stack>
    </>
  );
}
