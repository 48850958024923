import {
  Box,
  Button,
  FormLabel,
  Heading,
  Icon,
  Input,
  Text,
  Link,
  useToast,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { FaArrowLeft } from "react-icons/fa";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { POST } from "../../utilities/ApiProvider";

function NewPassword() {
  const toast = useToast();
  const navigate = useNavigate();
  const [showpass, setshowpass] = useState(false);
  const [reshowpass, setreshowpass] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newpass, setnewpass] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    const localEmail = localStorage.getItem("forgotemail");
    setnewpass((prev) => ({
      ...prev,
      email: localEmail,
    }));
  }, []);

  const validatePassword = () => {
    if (newpass.password.length < 8) {
      toast({
        description: "Password must be at least 8 characters long",
        status: "error",
        position: "top-right",
        duration: 3000,
      });
      return false;
    }

    if (newpass.password !== newpass.confirmPassword) {
      toast({
        description: "Passwords do not match",
        status: "error",
        position: "top-right",
        duration: 3000,
      });
      return false;
    }

    return true;
  };

  const EmpSubmit = async () => {
    if (!validatePassword()) return;

    setIsLoading(true);
    try {
      let res = await POST(`/users/change-password`, newpass);

      if (res.success === true) {
        navigate("/login");
        toast({
          description: res.message,
          status: "success",
          position: "top-right",
          duration: 3000,
        });
      }
    } catch (err) {
      toast({
        description: err.message,
        status: "error",
        position: "top-right",
        duration: 3000,
      });
    }
    setIsLoading(false);
  };

  return (
    <Box
      flex={1}
      p={{
        base: "60px 20px",
        md: "19px 40px",
        lg: "19px 60px",
        xl: "19px 60px",
      }}
      maxW={{ base: "100%", lg: "600px" }}
      m="auto"
    >
      <Link as={ReactLink} to="/" p={"0"}>
        <Button
          bgColor={"transparent"}
          _hover={{ bgColor: "transparent" }}
          _active={{ bgColor: "transparent" }}
          mb="20px"
          p={"0 !important"}
          fontWeight={"500"}
        >
          <Icon as={FaArrowLeft} mr="10px" /> Back
        </Button>
      </Link>
      <Text
        textAlign={useBreakpointValue({ base: "center", md: "left" })}
        fontFamily={"heading"}
        color={useColorModeValue("gray.800", "white")}
        textTransform={"uppercase"}
        fontWeight={700}
        fontSize={"32px"}
        my={8}
      >
        the little hub
      </Text>
      <Box className="applyForm">
        <Heading fontWeight={"500"} color={"black"} fontSize={"32px"} pb="20px">
          New Password
        </Heading>
        <Text fontWeight={"500"} color={"gray.500"} fontSize={"14px"} pb="20px">
          Set new password
        </Text>
        <Box>
          <FormLabel>Password*</FormLabel>
          <Box position={"relative"}>
            <Input
              type={showpass ? "text" : "password"}
              value={newpass.password}
              onChange={(e) =>
                setnewpass({ ...newpass, password: e.target.value })
              }
              placeholder="Enter Password"
            />
            <Button
              w={"40px"}
              position={"absolute"}
              top={"50%"}
              transform={"translateY(-50%)"}
              right={"0"}
              bgColor={"transparent"}
              _hover={{ bgColor: "transparent" }}
              _active={{ bgColor: "transparent" }}
              zIndex={1}
              onClick={() => setshowpass(!showpass)}
            >
              <Icon color={"#949494"} as={showpass ? BsEye : BsEyeSlash} />
            </Button>
          </Box>
        </Box>
        <Box>
          <FormLabel>Confirm Password*</FormLabel>
          <Box position={"relative"}>
            <Input
              type={reshowpass ? "text" : "password"}
              value={newpass.confirmPassword}
              onChange={(e) =>
                setnewpass({ ...newpass, confirmPassword: e.target.value })
              }
              placeholder="Enter Re-type Password"
            />
            <Button
              w={"40px"}
              position={"absolute"}
              top={"50%"}
              transform={"translateY(-50%)"}
              right={"0"}
              bgColor={"transparent"}
              _hover={{ bgColor: "transparent" }}
              _active={{ bgColor: "transparent" }}
              zIndex={1}
              onClick={() => setreshowpass(!reshowpass)}
            >
              <Icon color={"#949494"} as={reshowpass ? BsEye : BsEyeSlash} />
            </Button>
          </Box>
        </Box>

        <Button
          isLoading={isLoading}
          onClick={EmpSubmit}
          my="20px"
          bg="purple.500"
          fontSize={"14px"}
          fontWeight={500}
          color={"#fff"}
          borderRadius={"10px"}
          w="100%"
          h="45px"
          textTransform={"uppercase"}
          _hover={{
            bg: "purple.600",
          }}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
}

export default NewPassword;
