import React, { useEffect } from 'react'
import Header from '../../components/Website/Header';
import SmBanner from '../../components/Website/Herobanner/SmBanner'

import Footer from '../../components/Website/Footer';
import AboutDetail from '../../components/Website/About/Aboutdetail';
 

export default function AboutDetails() {

    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth', // Add smooth scrolling behavior
        });
      }, []);

  return (
    <>
    <Header />
    <SmBanner banheading={'About Us'}/>
    <AboutDetail/>
    <Footer/>
    </>
  )
}
