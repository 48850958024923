import React, { useEffect, useState, CSSProperties } from "react";
import CustHeading from "../../components/Dashboard/Heading/CustHeading";
import {
  Box,
  Grid,
  HStack,
  IconButton,
  Stack,
  Text,
  Heading,
  Button,
  Image,
  Icon,
  VStack,
  Divider,
  SimpleGrid,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import BarChart from "../../components/Dashboard/Chart/BarChart";
import { IoLocationSharp } from "react-icons/io5";
import { LuDollarSign } from "react-icons/lu";
import CustBtn from "../../components/Dashboard/Button/CustBtn";
import { GET } from "../../utilities/ApiProvider";
import Loader from "../../components/Dashboard/Loader/Loader";
import { RiUser6Line } from "react-icons/ri";
import { BsTicketDetailed } from "react-icons/bs";
import { FaRegEye } from "react-icons/fa6";
import { imgUrl } from "../../utilities/config";


function DashboardHome() {
  const data = [
    {
      name: "Total Day Care’s",
      value: "15",
      icon: LuDollarSign,
    },
    {
      name: "Total Click’s",
      value: "7,142",
      icon: LuDollarSign,
    },
    {
      name: "Total View’s",
      value: "13,524",
      icon: RiUser6Line,
    },
    {
      name: "View Logs",
      icon: RiUser6Line,
    },
  ];
  const [homeData, setHomeData] = useState(null);
  const fetchHomeData = async () => {
    try {
      const response = await GET("/daycare/dashboard");
      if (response?.statusCode === 200) {
        setHomeData(response?.data);
        console.log(response?.data);
      } else {
        setHomeData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchHomeData();
  }, []);



  if (!homeData) return <Loader />;
  return (
    <>
      <CustHeading
        name={"Dashboard"}
        subHeading={"Here is the latest update...Check Now!"}
      />
      <Stack>
        <SimpleGrid
          columns={{
            base: 1,
            md: 2,
            xl: 4,
            "2xl": 4,
          }}
          gap={6}
          mb={6}
        >
          {data.map((item, ind) => (
            <Box
              key={ind}
              p={5}
              shadow="md"
              borderWidth="1px"
              height={"200px"}
              borderRadius="16px"
              bg={ind === 0 ? "#784FCF" : "#EBEBEB"}
              display={"flex"}
              flexDirection={"column"}
              gap={"35px"}
              py={6}
            >
              <HStack>
                <Box
                  bg={ind === 0 ? "#F2F2F2" : "#784FCF"}
                  w={"40px"}
                  height={"40px"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  borderRadius={"50%"}
                >
                  <Icon
                    isRound={true}
                    variant="solid"
                    color={ind === 0 ? "#000" : "#fff"}
                    aria-label="Done"
                    fontSize="20px"
                    as={item?.icon}
                  />
                </Box>

                <Text fontSize={"18px"} color={ind === 0 ? "#fff" : "black"}>
                  {item?.name}
                </Text>
              </HStack>
              {ind === 3 ? (
                <Stack flexDirection={"column"}>
                  <HStack>
                    <Button
                      padding={{
                        md: "10px 15px",
                        "2xl": "10px 50px",
                      }}
                      fontSize={{ md: "13px", "2xl": "16px" }}
                      bg={"transparent"}
                      color={"#1A1B1A"}
                      as={Link}
                      to={"/dashboard/ViewLogs"}
                      fontWeight={"500"}
                      lineHeight={"inherit"}
                      borderRadius={12}
                      border={"1px solid #1A1B1A"}
                      _hover={{
                        bg: "#784FCF",
                        borderColor: "#784FCF",
                        color: "#fff",
                      }}
                      w={"100%"}
                      textAlign={"center"}
                    >
                      View Items
                    </Button>
                  </HStack>
                </Stack>
              ) : (
                <Stack
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  px={2}
                >
                  <Heading
                    as="h1"
                    fontWeight={"500"}
                    fontSize={"40px"}
                    color={ind === 0 ? "#fff" : "black"}
                  >
                    {ind === 0
                      ? homeData?.totalDayCares
                      : ind === 1
                      ? homeData?.totalClicks
                      : ind === 2
                      ? homeData?.totalViews
                      : homeData?.totalViewLogs}
                  </Heading>
                  {ind === 0 && (
                    <Text
                      as={Link}
                      to={"/dashboard/daycares"}
                      fontSize={"18px"}
                      color={ind === 0 ? "#fff" : "black"}
                      fontWeight={"500"}
                      h={"30px"}
                      borderBottom={"1px solid #fff"}
                      cursor={"pointer"}
                    >
                      View Details
                    </Text>
                  )}
                </Stack>
              )}
            </Box>
          ))}
        </SimpleGrid>
      </Stack>

      <Stack>
        <SimpleGrid
          columns={{
            base: 1,
            md: 1,
            xl: 2,
            "2xl": 2,
          }}
          gap={6}
          mb={6}
        >
          <Stack w={"100%"}>
            <BarChart data={homeData?.weeklyStats} />
          </Stack>
          <Stack w={"100%"}>
            <Stack mt={"-20px"}>
              <CustHeading name={"Most Viewed Day Care's"} />
            </Stack>
            <Box bg={"#EBEBEB"} p={5} borderRadius={"17px"} shadow={"md"}>
              {homeData?.topDayCares?.map((item, index) => (
                <>
                  <Stack
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    key={index}
                  >
                    <HStack>
                      <Box w="60px" h="60px">
                        <Image
                          borderRadius={"8px"}
                          src={
                            item?.image
                              ? `${imgUrl}/${item?.image}`
                              : "https://www.shutterstock.com/image-illustration/no-picture-available-placeholder-thumbnail-600nw-2179364083.jpg"
                          }
                        />
                      </Box>
                      <Box>
                        <Heading fontSize={"18px"} fontWeight={"bolder"}>
                          {item?.name}
                        </Heading>
                        <Stack
                          flexDirection="row"
                          mt={1}
                          alignItems="center"
                          spacing={2}
                        >
                          <Icon as={FaRegEye} />
                          <Text
                            marginLeft={"-5px"}
                            fontSize="14px"
                            fontWeight={500}
                          >
                            {item?.clicks}
                          </Text>
                        </Stack>
                      </Box>
                    </HStack>
                    <VStack>
                      <Button
                        as={Link}
                        bg={"#EBEBEB"}
                        px={{ base: 2, lg: 4 }}
                        border={"1px solid"}
                        to={`/dashboard/daycaresDetail/${item?._id}`}
                      >
                        View Details
                      </Button>
                    </VStack>
                  </Stack>
                  <Divider my={4} borderColor="#bbb" />
                </>
              ))}
            </Box>
          </Stack>
        </SimpleGrid>
      </Stack>
    </>
  );
}

export default DashboardHome;
