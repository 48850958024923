import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  ListItem,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  UnorderedList,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Curve from "../../../assets/images/contact.png";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaLinkedin,
  FaInstagram,
  FaTwitter,
  FaSkype,
  FaYoutube,
} from "react-icons/fa";
import { GoMail } from "react-icons/go";
import { POST } from "../../../utilities/ApiProvider";
import { IoLocationOutline } from "react-icons/io5";

export default function Contact({ socialLinks }) {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const formData = new FormData(e.target);
      const formDataEntries = Array.from(formData.entries());

      if (formDataEntries.some(([key, value]) => value === "")) {
        setLoading(false);
        toast({
          description: "Please fill all the fields",
          status: "error",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      const response = await POST("/contact/submit", formData);
      setLoading(true);

      if (response.success === true) {
        setLoading(false);

        toast({
          title: "Message Sent Successfully",
          status: "success",
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
        e.target.reset();
      } else {
        setLoading(false);

        toast({
          title: response.message,
          status: "error",
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Stack id="contactus" bg={"#F2F2F2"}>
        <Image src={Curve} w="100%" alt="" />
        <Container py={4} maxW={{ base: "8xl", xl: "6xl", "2xl": "8xl" }}>
          <Stack py={16}>
            <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} spacing={10}>
              <Stack gap={8}>
                <Stack gap={4}>
                  <Heading
                    fontSize={{
                      base: "25px",
                      md: "30px",
                      xl: "30px",
                      "2xl": "30px",
                    }}
                    color={"#111"}
                  >
                    Contact Details
                  </Heading>
                  <Text
                    fontSize={"15px"}
                    fontWeight={500}
                    w={{ base: "100%", md: "60%", xl: "60%" }}
                  >
                    If you have any questions or need more information about our services, feel free to reach out to us. We're here to help!
                  </Text>
                  <Button
                    p={0}
                    width={"max-content"}
                    bg={"transparent"}
                    as={Link}
                    display={"flex"}
                    to={"mailto:info@example.com"}
                  >
                    <Box
                      bg={"#784FCF"}
                      width={"40px"}
                      height={"40px"}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      borderRadius={"50%"}
                    >
                      <GoMail color={"#fff"} />
                    </Box>
                    <Text pl={2} color={"#111"} fontWeight={600}>
                    info@thelittlehub.co
                    </Text>
                  </Button>
                  <Flex gap={2}>
                    <Box
                      bg={"#784FCF"}
                      width={"40px"}
                      height={"40px"}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      borderRadius={"50%"}
                    >
                      <IoLocationOutline color={"#fff"} />
                    </Box>
                    <Text color={"#111"} fontWeight={600}>
                      The Little Hub Daycare Services Inc. <br/>
                      Suite 725, 6388 No.3 Road <br />Richmond British Columbia <br />Canada <br />V6Y 0L4
                    </Text>
                  </Flex>
                </Stack>

                <Stack gap={4}>
                 <Box>
                 <Heading fontSize={"22px"} color={"#111"}>
                    Follow Us On:
                  </Heading>
                  <Text fontSize={'15px'} color={"#111"} fontWeight={500}>
                  Stay connected with us on social media<br/> to get the latest updates and news.
                  </Text>
                 </Box>
                  <UnorderedList
                    ml={0}
                    display={"flex"}
                    gap={4}
                    listStyleType={"none"}
                  >
                    {socialLinks?.map(
                      (link) =>
                        link.isChecked && (
                          <ListItem
                            bg={"#784FCF"}
                            width={"40px"}
                            height={"40px"}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            borderRadius={"50%"}
                            key={link._id}
                          >
                            <Link target="_blank" to={link.url}>
                              {link.title === "Facebook" && < FaFacebookF color={'#fff'} />}
                              {link.title === "Linkedin" && <FaLinkedin  color={'#fff'} />}
                              {link.title === "Instagram" && <FaInstagram  color={'#fff'} />}
                              {link.title === "Twitter" && <FaTwitter  color={'#fff'} />}
                              {link.title === "Skype" && <FaSkype  color={'#fff'} />}
                              {link.title === "Youtube" && <FaYoutube  color={'#fff'} />}
                            </Link>
                          </ListItem>
                        )
                    )}
                  </UnorderedList>
                </Stack>
              </Stack>
              <Stack gap={8}>
               <Box>
               <Heading
                  fontSize={{
                    base: "25px",
                    md: "30px",
                    xl: "30px",
                    "2xl": "30px",
                  }}
                  color={"#111"}
                >
                  Get In Touch
                </Heading>
                <Text fontSize={'15px'} color={"#111"} fontWeight={500}>
                Have any inquiries? Fill out the form below, and we’ll get back to you as soon as possible.
                </Text>
               </Box>
                <form onSubmit={handleSubmit}>
                  <Stack gap={6}>
                    <SimpleGrid columns={2} spacing={6}>
                      <Box>
                        <FormControl>
                          <FormLabel fontSize={"15px"}>First Name</FormLabel>
                          <Input
                            py={6}
                            border={"1px solid #784FCF"}
                            name="firstname"
                            type="text"
                            placeholder="Enter First Name"
                            isRequired
                          />
                        </FormControl>
                      </Box>
                      <Box>
                        <FormControl>
                          <FormLabel fontSize={"15px"}>Last Name</FormLabel>
                          <Input
                            py={6}
                            border={"1px solid #784FCF"}
                            name="lastname"
                            type="text"
                             placeholder="Enter Last Name"
                             isRequired
                          />
                        </FormControl>
                      </Box>
                      <Box>
                        <FormControl>
                          <FormLabel fontSize={"15px"}>Email Address</FormLabel>
                          <Input
                            py={6}
                            border={"1px solid #784FCF"}
                            name="email"
                            type="email"
                             placeholder="Enter Email "
                             isRequired
                          />
                        </FormControl>
                      </Box>
                      <Box>
                        <FormControl>
                          <FormLabel fontSize={"15px"}>Phone No.</FormLabel>
                          <Input
                            py={6}
                            border={"1px solid #784FCF"}
                            name="number"
                            type="number"
                             placeholder="Enter Phone No."
                             isRequired
                          />
                        </FormControl>
                      </Box>
                    </SimpleGrid>
                    <Box>
                      <FormControl>
                        <FormLabel fontSize={"15px"}>Messages</FormLabel>
                        <Textarea
                          border={"1px solid #784FCF"}
                          height={"250px"}
                          name="message"
                          placeholder="Enter Message"
                          isRequired
                        />
                      </FormControl>
                    </Box>
                    <Button
                      _hover={{
                        bg: "#784FCF",
                      }}
                      type="submit"
                      bg={"#784FCF"}
                      color={"#fff"}
                      py={"25px"}
                      px={"50px"}
                      width={"max-content"}
                      isLoading={loading}
                    >
                      Submit
                    </Button>
                  </Stack>
                </form>
              </Stack>
            </SimpleGrid>
          </Stack>
        </Container>
      </Stack>
    </>
  );
}
