import {
  Box,
  Heading,
  Stack,
  Text,
  Spinner,
  Button,
  Icon,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { MdDeleteOutline } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import CustHeading from "../../components/Dashboard/Heading/CustHeading";
import { DELETE, GET } from "../../utilities/ApiProvider";
import Loader from "../../components/Dashboard/Loader/Loader";
import { IoArrowBackSharp } from "react-icons/io5";
export default function SingleContactDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setdata] = useState({});
  useEffect(() => {
    fetchInquiry(id);
  }, [id]);

  async function fetchInquiry(id) {
    setIsLoading(true);
    try {
      const response = await GET(`/contact/${id}`);
      console.log("rrrr", response);

      if (response.success === true) {
        console.log("inquery ", response.data, id);
        setdata(response.data);
        setIsLoading(false);
      } else {
        setdata([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleRemove = async () => {
    try {
      const response = await DELETE(`/contact/${id}`);
      console.log(response);
      if (response?.statusCode === 200) {
        navigate("/dashboard/Contact-Queries");
        toast({
          title: "Contact Query Deleted Successfully",
          status: "success",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Contact Query Deleted Failed",
          status: "success",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Stack
      // direction={"row"}
      // alignItems={"center"}
      // justifyContent={"space-between"}
      >
        <Button
          onClick={() => navigate("/dashboard/Contact-Queries")}
          color={"#fff"}
          bg={"#2E8B44"}
          w={"max-content"}
          textTransform={"uppercase"}
          fontSize={"14px"}
          _hover={{
            bg: "#2E8B44",
          }}
        >
          <Icon mr={2} fontSize={"20px"} as={IoArrowBackSharp} /> Back
        </Button>
        <CustHeading
          name={"Contact Details"}
          subHeading={"Here is the contact queries list...Check Now!"}
        />
      </Stack>

      {isLoading ? (
        <Loader />
      ) : (
        <Stack gap={4}>
          <Stack width={"30%"}>
            <Heading fontSize={"16px"}>Name</Heading>
            <Box
              borderRadius={6}
              py={4}
              px={3}
              border={"1px solid #e5e4e4"}
              bg={"#F8F8F8"}
            >
              <Text>{data?.firstname || "John"}</Text>
            </Box>
          </Stack>
          <Stack width={"30%"}>
            <Heading fontSize={"16px"}>Last Name</Heading>
            <Box
              borderRadius={6}
              py={4}
              px={3}
              border={"1px solid #e5e4e4"}
              bg={"#F8F8F8"}
            >
              <Text>{data?.lastname || "John"}</Text>
            </Box>
          </Stack>
          <Stack width={"30%"}>
            <Heading fontSize={"16px"}>Email Address</Heading>
            <Box
              borderRadius={6}
              py={4}
              px={3}
              border={"1px solid #e5e4e4"}
              bg={"#F8F8F8"}
            >
              <Text>{data?.email || "johndoe534@gmail.com"}</Text>
            </Box>
          </Stack>
          <Stack width={"30%"}>
            <Heading fontSize={"16px"}>Phone No.</Heading>
            <Box
              borderRadius={6}
              py={4}
              px={3}
              border={"1px solid #e5e4e4"}
              bg={"#F8F8F8"}
            >
              <Text>{data?.number || "+(966) 763 282 46"}</Text>
            </Box>
          </Stack>
          <Stack width={"30%"}>
            <Heading fontSize={"16px"}>Message</Heading>
            <Box
              borderRadius={6}
              py={4}
              px={3}
              border={"1px solid #e5e4e4"}
              bg={"#F8F8F8"}
            >
              <Text>
                {data?.message ||
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."}
              </Text>
            </Box>
          </Stack>
          <Stack width={"30%"}>
            <Button
              bg={"#E23D40"}
              color={"#fff"}
              padding={"22px 30px"}
              fontSize={"20px"}
              borderRadius={"10px"}
              onClick={handleRemove}
            >
              <Icon as={MdDeleteOutline} />
            </Button>
          </Stack>
        </Stack>
      )}
    </>
  );
}
