import React from "react";
import {
  Box,
  HStack,
  Heading,
  Text,
  Image,
  UnorderedList,
  ListItem,
  Link,
  Button,
  Stack,
  Container,
  SimpleGrid,
} from "@chakra-ui/react";
import One from "../../../assets/images/1.svg";
import BLine from "../../../assets/images/box.svg";
import Hero from "../../../assets/images/hero-img.png";
import Curve from "../../../assets/images/curve.png";
import HeroB from "../../../assets/images/hero-b.png";
import HeroA from "../../../assets/images/hero-a.png";

export default function Mainbanner() {
  return (
    <>
      <Stack bg={"#E9E5F4"} pt={20} position={"relative"}>
        <Image
          margin={"auto"}
          position={"absolute"}
          left={0}
          bottom={"0"}
          top={0}
          src={HeroB}
          alt=""
        />
        <Image position={"absolute"} right={0} top={20} src={HeroA} alt="" />
        <Container py={4} maxW={{ base: "8xl", xl: "6xl", "2xl": "8xl" }}>
          <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} spacing={10}>
            <Stack gap={8}>
              <Stack gap={4} textAlign={{ base: "center", md: "left" }}>
                <Text
                  textTransform={"uppercase"}
                  fontWeight={700}
                  color={"#784FCF"}
                  fontSize={"16px"}
                >
                  The Little Hub Daycare Services
                </Text>
                <Heading
                  fontSize={{
                    base: "30px",
                    md: "35px",
                    xl: "40px",
                    "2xl": "50px",
                  }}
                  fontWeight={700}
                  color={"#111"}
                  textTransform={'capitalize'}
                >
                  Canada’s Trusted Daycare & Childcare Centers <br/>at Your Fingertips
                </Heading>
                <Text
                  pr={{ base: "0", md: "40px", xl: "45px", "2xl": 48 }}
                  fontSize={{ base: "14px", md: "16px", xl: "16px" }}
                  fontWeight={500}
                >
                  Looking for trusted childcare near you? Discover our top-rated
                  daycare services where safety, fun, and learning come
                  together. At The Little Hub, we ensure a nurturing environment
                  where children thrive and grow, all while receiving
                  personalized care.
                </Text>
          
              </Stack>
              <HStack gap={{ base: 2, md: 2, xl: 6 }}>
                <Stack gap={2} textAlign={{ base: "center", md: "left" }}>
                  <Image
                    margin={{ base: "auto", md: "0", xl: "0" }}
                    width={"50px"}
                    src={One}
                    alt=""
                  />
                  <Text
                    color={"#111"}
                    fontWeight={600}
                    fontSize={{ base: "13px", md: "16px", xl: "16px" }}
                  >
                    Trusted Daycares
                  </Text>
                  <Text fontSize={{ base: "13px", md: "16px", xl: "16px" }} maxW={"370px"}>
                  We take pride in providing certified and trusted daycare centers, giving parents peace of mind while their children are in capable hands.
                  </Text>
                </Stack>
                {/* <Stack gap={2} textAlign={{ base: "center", md: "left" }}>
                  <Image
                    margin={{ base: "auto", md: "0", xl: "0" }}
                    width={"50px"}
                    src={BLine}
                    alt=""
                  />
                  <Text
                    color={"#111"}
                    fontWeight={600}
                    fontSize={{ base: "13px", md: "16px", xl: "16px" }}
                  >
                    Curiosity & Exploration
                  </Text>
                  <Text fontSize={{ base: "13px", md: "16px", xl: "16px" }}>
                  Our programs encourage curiosity, helping children explore and discover the world around them through interactive and fun learning experiences.
                  </Text>
                </Stack> */}
              </HStack>
              <Button
                  as={"a"}
                  margin={{ base: "auto", md: "0", xl: "0" }}
                  fontSize={"sm"}
                  fontWeight={600}
                  color={"white"}
                  width={"max-content"}
                  padding={"14px 40px"}
                  bg={"#784FCF"}
                  href={"#contactus"}
                  _hover={{
                    bg: "#784FCF",
                  }}
                >
                  Contact Us
                </Button>
            </Stack>
            <Stack>
              <Image
                display={{ base: "none", md: "block" }}
                src={Hero}
                alt=""
              />
            </Stack>
          </SimpleGrid>
        </Container>
        <Image src={Curve} w="100%" alt="" />
      </Stack>
    </>
  );
}
