import React, { useEffect, useState } from "react";
import CustHeading from "../../components/Dashboard/Heading/CustHeading";
import {
  Box,
  Button,
  Heading,
  Icon,
  SimpleGrid,
  Stack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  FormControl,
  FormLabel,
  useToast,
  Textarea,
} from "@chakra-ui/react";
import { RiEditLine } from "react-icons/ri";
import { MdDeleteOutline } from "react-icons/md";
import { DELETE, GET, POST, PUT } from "../../utilities/ApiProvider";
import Loader from "../../components/Dashboard/Loader/Loader";

export default function Faqs() {
  const toast = useToast();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editMode, setEditMode] = useState(false);
  const [faqData, setfaqData] = useState({
    question: "",
    answer: "",
  });
  const [selectedFAQId, setSelectedFAQId] = useState(null);

  const getFAQs = async () => {
    try {
      const response = await GET("/faq/");
      console.log(response);
      if (response?.statusCode === 200) {
        setData(response?.data);
      } else {
        setData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteFAQ = async (id) => {
    try {
      const response = await DELETE(`/faq/delete/${id}`);
      console.log(response);
      if (response?.success === true) {
        getFAQs();
        toast({
          title: "FAQ Deleted Successfully",
          status: "success",
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
      } else {
        toast({
          title: response.message,
          status: "error",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Failed to delete FAQ",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const addFAQ = async () => {
    setIsLoading(true);
    if (faqData.question.trim() === "" || faqData.answer.trim() === "") {
      toast({
        description: "Please fill all the fields",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
      return;
    }
    try {
      const response = await POST("/faq/create", faqData);
      console.log(response);
      if (response?.success === true) {
        setIsLoading(false);
        toast({
          title: "FAQ Added Successfully",
          status: "success",
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
        getFAQs();
        onClose();
        setfaqData({
          question: "",
          answer: "",
        });
      } else {
        setIsLoading(false);
        toast({
          description: response?.message,
          status: "error",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast({
        title: "Failed to add FAQ",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const editFAQ = async () => {
    setIsLoading(true);
    if (faqData.question.trim() === "" || faqData.answer.trim() === "") {
      toast({
        description: "Please fill all the fields",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
      return;
    }
    try {
      const response = await PUT(`/faq/update/${selectedFAQId}`, faqData);
      console.log(response);
      if (response?.success === true) {
        setIsLoading(false);
        toast({
          title: "FAQ Updated Successfully",
          status: "success",
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
        getFAQs();
        onClose();
        setfaqData({
          question: "",
          answer: "",
        });
        setEditMode(false);
        setSelectedFAQId(null);
      } else {
        setIsLoading(false);
        toast({
          description: response?.message,
          status: "error",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast({
        title: "Failed to update FAQ",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleEditClick = (faq) => {
    setfaqData({
      question: faq.question,
      answer: faq.answer,
    });
    setSelectedFAQId(faq._id);
    setEditMode(true);
    onOpen();
  };

  const handleSubmit = () => {
    if (editMode) {
      editFAQ();
    } else {
      addFAQ();
    }
  };

  useEffect(() => {
    getFAQs();
  }, []);

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <CustHeading
          name={"FAQ’s"}
          subHeading={"Here is the faq’s list...Check Now!"}
        />
        <Button
          bg={"#784FCF"}
          color={"#fff"}
          padding={"22px 25px"}
          w={"max-content"}
          fontSize={"15px"}
          borderRadius={"10px"}
          onClick={onOpen}
        >
          Add New FAQ
        </Button>
      </Box>
      {!data ? (
        <Loader />
      ) : (
        data.length > 0 ?
        (<Stack>
          
          <Stack>
            <SimpleGrid columns={{
              base: 2,
              md: 2,
              xl: 3,
              "2xl": 3,
            }} F spacing={10}>
              {data?.map((item, index) => (
                <Stack
                  key={index}
                  gap={6}
                  borderRadius={8}
                  px={4}
                  pt={4}
                  pb={6}
                  bg={"#EBEBEB"}
                >
                  <Stack justifyContent={"end"} direction={"row"} gap={2}>
                    <Button
                      onClick={() => handleEditClick(item)}
                      color={"#fff"}
                      bg={"#784FCF"}
                      w={"max-content"}
                    >
                      <Icon as={RiEditLine} />
                    </Button>
                    <Button
                      onClick={() => deleteFAQ(item?._id)}
                      color={"#fff"}
                      bg={"#F31E26"}
                      w={"max-content"}
                    >
                      <Icon as={MdDeleteOutline} />
                    </Button>
                  </Stack>
                  <Stack>
                    <Heading fontSize={"20px"}>{item?.question}</Heading>
                    <Text>{item?.answer}</Text>
                  </Stack>
                </Stack>
              ))}
            </SimpleGrid>
          </Stack>
        </Stack>) : 
        (<Stack>
          <Text px={4} fontSize={"20px"} fontWeight={"500"}>
            No FAQ's Found
          </Text>
        </Stack>)
      )}

      <Modal isOpen={isOpen} onClose={() => {
        setEditMode(false);
        setSelectedFAQId(null);
        setfaqData({
          question: "",
          answer: "",
        });
        onClose();
      }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{editMode ? "Edit FAQ" : "Add New FAQ"}</ModalHeader>
          <Text ml={6}>
            {editMode ? "Update the details below" : "Enter the details below to add new FAQ"}
          </Text>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <Box>
                <FormControl>
                  <FormLabel fontSize={"15px"}>Question</FormLabel>
                  <Input
                    border={"1px solid #784FCF"}
                    name="question"
                    type="text"
                    value={faqData.question}
                    onChange={(e) =>
                      setfaqData({ ...faqData, question: e.target.value })
                    }
                    placeholder="Enter Here"
                  />
                </FormControl>
              </Box>
              <Box>
                <FormControl>
                  <FormLabel fontSize={"15px"}>Answer</FormLabel>
                  <Textarea
                    height={"100px"}
                    border={"1px solid #784FCF"}
                    name="answer"
                    type="text"
                    value={faqData.answer}
                    placeholder="Enter Here"
                    onChange={(e) =>
                      setfaqData({ ...faqData, answer: e.target.value })
                    }
                  ></Textarea>
                </FormControl>
              </Box>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              mr={3}
              bg={"#784FCF"}
              color={"#fff"}
              fontSize={"16px"}
              fontWeight={"500"}
              _hover={{
                bg: "#784FCF",
                color: "#fff",
              }}
              _active={{
                bg: "#784FCF",
                color: "#fff",
              }}
              w="187px"
              h="40px"
              borderRadius={"5.5px"}
              onClick={handleSubmit}
              isLoading={isLoading}
            >
              {editMode ? "Update" : "Save"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
