// import {
//   Button,
//   Container,
//   Heading,
//   Image,
//   SimpleGrid,
//   Stack,
//   Text,
// } from "@chakra-ui/react";
// import React from "react";
// import backbg from "../../../assets/images/bluebg.png";
// import Aboutr from "../../../assets/images/abr.jpg";
// import { Link } from "react-router-dom";

// export default function AboutUs() {
//   return (
//     <>
//       <Stack id="aboutus" backgroundImage={backbg} py={20} gap={12}>
//         <Stack textAlign={"center"}>
//           <Heading color={"#fff"}>About Us</Heading>
//         </Stack>
//         <Container py={4} maxW={{ base: "8xl", xl: "6xl", "2xl": "8xl" }}>
//           <SimpleGrid alignItems={'center'} columns={2} gap={8}>
//             <Stack gap={6}>
//               <Heading as={"h4"} fontSize={"20px"} color={"#fff"}>
//                 The Little Hub
//               </Heading>
//               <Heading
//                 as={"h2"}
//                 fontSize={{
//                   base: "25px",
//                   md: "30px",
//                   xl: "30px",
//                   "2xl": "30px",
//                 }}
//                 color={"#fff"}
//               >
//                 Redefining Daycare for Canadian Families
//               </Heading>
//               <Text fontSize={"16px"} color={"#fff"}>
//                 At The Little Hub, our mission is clear: we aim to transform the
//                 daycare landscape in Canada by addressing three critical needs
//                 that affect families across the nation—affordability,
//                 accessibility, and quality. With the ever-growing challenges
//                 that parents face in finding suitable daycare options, The
//                 Little Hub is here to make the process smoother, more efficient,
//                 and tailored to each family’s unique needs.
//               </Text>
//               <Text fontSize={"16px"} color={"#fff"}>
//                 Founded by Azfar Salam, The Little Hub is the brainchild of a
//                 parent and entrepreneur who understands the importance of
//                 reliable, high-quality daycare. As a solution-driven platform,
//                 we provide both parents and daycare providers with tools that
//                 empower them to make informed decisions, streamline operations,
//                 and maximize enrollment capacity.
//               </Text>
//               <Heading as={"h4"} fontSize={"20px"} color={"#fff"}>
//                 Our Mission
//               </Heading>
//               <Text fontSize={"16px"} color={"#fff"}>
//                 At the heart of The Little Hub is the goal of making daycare
//                 more accessible and affordable while maintaining the highest
//                 standards of quality. With increasing demand for daycare
//                 services, particularly in high-density urban areas, our platform
//                 offers a reliable solution for both families and daycare
//                 centers. Through innovative digital tools and AI-powered
//                 features, we ensure that parents can find, compare, and secure
//                 daycare services that fit their budget, location, and
//                 preferences.
//               </Text>
//               <Text fontSize={"16px"} color={"#fff"}>
//                 We believe that every child deserves access to quality care, and
//                 every parent should have peace of mind knowing their child is in
//                 a safe and nurturing environment. That’s why we strive to create
//                 a seamless experience that not only saves time but also brings
//                 families closer to the care they need.
//               </Text>
//               <Button
//                 as={Link}
//                  margin={{base:'auto', md:'0', xl:'0'}}
//                   fontSize={"sm"}
//                   fontWeight={600}
//                   color={"#784FCF"}
//                   width={"max-content"}
//                   padding={"14px 40px"}
//                   bg={"#EAE4F4"}
//                    to={"/AboutDetails"}
                  
//                 >
//                   View More Details
//                 </Button>
//             </Stack>
//             <Stack>
//               <Image borderRadius={8} src={Aboutr} alt="Aboutus" />
//             </Stack>
//           </SimpleGrid>
//         </Container>
//       </Stack>
//     </>
//   );
// }

import {
  Button,
  Container,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import backbg from "../../../assets/images/bluebg.png";
import Aboutr from "../../../assets/images/abr.jpg";
import { Link } from "react-router-dom";

export default function AboutUs() {
  return (
    <>
      <Stack id="aboutus" backgroundImage={backbg} py={{ base: 10, md: 20 }} gap={8}>
        <Stack textAlign="center" px={{ base: 4, md: 0 }}>
          <Heading color="#fff" fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}>
            About Us
          </Heading>
        </Stack>
        <Container py={4} maxW={{ base: "full", md: "8xl", xl: "6xl" }}>
          <SimpleGrid columns={{ base: 1, md: 2 }} alignItems="center" gap={{ base: 6, md: 8 }}>
            <Stack
              order={{ base: 2, md: 1 }} // Text is displayed after the image on mobile
              gap={6}
              px={{ base: 4, md: 0 }}
              textAlign={{ base: "center", md: "left" }}
            >
              <Heading as="h4" fontSize={{ base: "18px", md: "20px" }} color="#fff">
                The Little Hub
              </Heading>
              <Heading
                as="h2"
                fontSize={{ base: "22px", md: "26px", lg: "30px" }}
                color="#fff"
              >
                Redefining Daycare for Canadian Families
              </Heading>
              <Text fontSize={{ base: "14px", md: "16px" }} color="#fff" textAlign="justify">
                At The Little Hub, our mission is clear: we aim to transform the
                daycare landscape in Canada by addressing three critical needs that
                affect families across the nation—affordability, accessibility, and
                quality. With the ever-growing challenges that parents face in finding
                suitable daycare options, The Little Hub is here to make the process
                smoother, more efficient, and tailored to each family’s unique needs.
              </Text>
              <Text fontSize={{ base: "14px", md: "16px" }} color="#fff" textAlign="justify">
                Founded by Azfar Salam, The Little Hub is the brainchild of a
                parent and entrepreneur who understands the importance of reliable,
                high-quality daycare. As a solution-driven platform, we provide both
                parents and daycare providers with tools that empower them to make
                informed decisions, streamline operations, and maximize enrollment capacity.
              </Text>
              <Heading as="h4" fontSize={{ base: "18px", md: "20px" }} color="#fff">
                Our Mission
              </Heading>
              <Text fontSize={{ base: "14px", md: "16px" }} color="#fff" textAlign="justify">
                At the heart of The Little Hub is the goal of making daycare
                more accessible and affordable while maintaining the highest
                standards of quality. With increasing demand for daycare services,
                particularly in high-density urban areas, our platform offers a reliable
                solution for both families and daycare centers. Through innovative
                digital tools and AI-powered features, we ensure that parents can find,
                compare, and secure daycare services that fit their budget, location,
                and preferences.
              </Text>
              <Text fontSize={{ base: "14px", md: "16px" }} color="#fff" textAlign="justify">
                We believe that every child deserves access to quality care, and
                every parent should have peace of mind knowing their child is in a
                safe and nurturing environment. That’s why we strive to create a seamless
                experience that not only saves time but also brings families closer to
                the care they need.
              </Text>
              <Button
                as={Link}
                margin={{ base: "auto", md: "0" }}
                fontSize="sm"
                fontWeight={600}
                color="#784FCF"
                width="max-content"
                padding="14px 40px"
                bg="#EAE4F4"
                to="/AboutDetails"
                _hover={{ bg: "#C5B8E0" }}
              >
                View More Details
              </Button>
            </Stack>
            <Stack order={{ base: 1, md: 2 }} align="center">
              <Image borderRadius={8} src={Aboutr} alt="About us" maxW="100%" />
            </Stack>
          </SimpleGrid>
        </Container>
      </Stack>
    </>
  );
}
