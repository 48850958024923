import React, { useEffect } from 'react'
import Header from '../../components/Website/Header';
import SmBanner from '../../components/Website/Herobanner/SmBanner'
import Privacy from '../../components/Website/Privacy';
import Footer from '../../components/Website/Footer';

export default function PrivacyPolicy() {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Add smooth scrolling behavior
    });
  }, []);

  return (
    <>
    <Header />
    <SmBanner banheading={'Privacy Policy'}/>
    <Privacy/>
    <Footer/>
    </>
  )
}
