import { Container, Heading, Image, Stack } from "@chakra-ui/react";
import React from "react";
import Curve from "../../../assets/images/curve.png";

export default function SmBanner({banheading}) {
  return (
    <>
      <Stack  pt={{base:10, md:20, xl:20, "2xl": 20}} bg={"#E9E5F4"}>
        <Container
          py={4}
          maxW={{ base: "8xl", xl: "6xl", "2xl": "8xl" }}
        >
            <Stack textAlign={'center'} pb={6}>
                <Heading fontSize={{ base: "25px", md: "30px", xl: "30px", "2xl": "35px" }}>{banheading}</Heading>
            </Stack>

        </Container>
        <Image src={Curve} w="100%" alt="" />
      </Stack>
    </>
  );
}
