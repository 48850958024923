import { Stack, Heading, Text } from "@chakra-ui/react";
import React from "react";

function CustHeading({ name, subHeading }) {
  return (
    <Stack px={3} py={2} spacing={1}>
      <Heading fontSize={"30px"} fontWeight={500}>
        {name}
      </Heading>
      {/* <Text fontSize={"15px"} color={"#75767A"} fontWeight={"400"}>
        {subHeading}
      </Text> */}
    </Stack>
  );
}

export default CustHeading;
