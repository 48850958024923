import { Button } from "@chakra-ui/react";
import React from "react";

function CustBtn({ name }) {
  return (
    <Button bg={"#EBEBEB"} px={{ base: 2, lg: 4 }} border={"1px solid"}>
      {name}
    </Button>
  );
}

export default CustBtn;
