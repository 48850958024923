// import { border, Box, Flex, Heading } from "@chakra-ui/react";
// import React from "react";
// import Chart from "react-apexcharts";

// const BarChart = ({ data }) => {
//   const options = {
//     chart: {
//       type: "bar",
//       height: 400, // Increase the height of the chart
//       toolbar: {
//         show: false,
//       },
//     },
//     plotOptions: {
//       bar: {
//         horizontal: false,
//         columnWidth: "80%",
//         endingShape: "rounded",
//         borderRadius: 3,
//       },
//     },
//     colors: ["#EDA02D", "#ED3B3B", "#FC8552"],

//     dataLabels: {
//       enabled: false,
//     },
//     stroke: {
//       show: true,
//       width: 2,
//       colors: ["transparent"],
//     },
//     xaxis: {
//       categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
//     },
//     fill: {
//       opacity: 1,
//     },
//     legend: {
//       position: "top",
//       horizontalAlign: "right",
//       fontSize: "14px",
//       fontWeight: "500",
//     },
//     // tooltip: {
//     //   y: {
//     //     formatter: function (val) {
//     //       return "$ " + val + " thousands";
//     //     },
//     //   },
//     // },
//   };

//   const series = [
//     {
//       name: "Net Profit",
//       data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
//     },
//     {
//       name: "Revenue",
//       data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
//     },
//     {
//       name: "Free Cash Flow",
//       data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
//     },
//   ];

//   return (
//     <Box id="chart" height="400px" width="100%" position="relative">
//       <Flex
//         justifyContent="space-between"
//         alignItems="center"
//         mb={4}
//         position="absolute"
//         top={0}
//         left={0}
//         right={0}
//         px={6}
//       >
//         <Heading fontSize="32px" fontWeight="500">
//           User Statistics
//         </Heading>
//       </Flex>
//       <Chart
//         options={options}
//         series={data || series}
//         type="bar"
//         height="100%"
//         width="100%"
//       />
//     </Box>
//   );
// };

// export default BarChart;



import { Box, Flex, Heading } from "@chakra-ui/react";
import React from "react";
import Chart from "react-apexcharts";

const BarChart = ({ data }) => {
  // Function to get the last 7 days' dates formatted as MM/DD
  const getLastSevenDays = () => {
    const labels = [];
    const today = new Date();

    for (let i = 6; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      labels.push(date.toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "2-digit" })); // Format as MM/DD
    }

    return labels.reverse(); // Reverse to have the most recent date on the right
  };

  const options = {
    chart: {
      type: "bar",
      height: 400, // Increase the height of the chart
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "80%",
        endingShape: "rounded",
        borderRadius: 3,
      },
    },
    colors: ["#EDA02D", "#ED3B3B", "#FC8552"],

    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: getLastSevenDays(), // Use dynamic labels (formatted dates)
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      fontSize: "14px",
      fontWeight: "500",
    },
  };

  // Ensure the series data reflects the visitors from different devices
  const series = [
    {
      name: "Mobile",
      data: data[0]?.data || [0, 0, 0, 0, 0, 0, 0], // Default values if no data
    },
    {
      name: "Tablet",
      data: data[1]?.data || [0, 0, 0, 0, 0, 0, 0],
    },
    {
      name: "Desktop",
      data: data[2]?.data || [0, 0, 0, 0, 0, 0, 0],
    },
  ];

  return (
    <Box id="chart" height="400px" width="100%" position="relative">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        position="absolute"
        top={0}
        left={0}
        right={0}
        px={6}
      >
        <Heading fontSize="32px" fontWeight="500">
          User Statistics
        </Heading>
      </Flex>
      <Chart
        options={options}
        series={series}
        type="bar"
        height="100%"
        width="100%"
      />
    </Box>
  );
};

export default BarChart;
