import React, { useEffect, useState } from "react";
import Header from "../../components/Website/Header";
import Mainbanner from "../../components/Website/Herobanner/Mainbanner";
import MapFilter from "../../components/Website/Map";
import AboutUs from "../../components/Website/About/Index";
import Faqs from "../../components/Website/Faqs";
import Contact from "../../components/Website/Contact";
import Footer from "../../components/Website/Footer";
import { GET, POST } from "../../utilities/ApiProvider";
import { useLocation } from "react-router-dom";
import { Box, Spinner, Stack, Text } from "@chakra-ui/react";
import Testing from "../../components/Website/Map/Testing";
import { isMobile, isTablet, isDesktop } from "react-device-detect";

export default function Home() {
  const [Page, setPage] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchLaningPage();
    const device = getDevice();
    postData(device);
  }, []);

  async function postData(device) {
    const response = await POST(`/user-activity`, {
      device,
    });
    console.log(response);
  }
  const location = useLocation();
  function getDevice() {
    if (isMobile) {
      return "mobile";
    } else if (isTablet) {
      return "tablet";
    } else {
      return "desktop";
    }
  }
  useEffect(() => {
    const scrollToSection = () => {
      const hash = location.hash;
      if (hash) {
        const element = document.getElementById(hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    };

    // Slightly delay the scrolling action to ensure proper loading
    setTimeout(scrollToSection, 0);
  }, [location]);

  async function fetchLaningPage() {
    setIsLoading(true);
    try {
      const response = await GET("/landing-page");
      if (response.success === true) {
        localStorage.setItem("logo", response?.data?.websetting?.logo);
        setPage(response.data);
        setIsLoading(false);
      } else {
        setPage([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      {isLoading ? (
        <Box
          display={"flex"}
          height={"100vh"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Stack>
            <Text
              textTransform={"uppercase"}
              fontWeight={700}
              fontSize={"22px"}
            >
              the little hub
            </Text>
            <Box textAlign={"center"}>
              <Spinner color="#47A046" />
            </Box>
          </Stack>
        </Box>
      ) : (
        <>
          <Header />
          <Mainbanner />
          <MapFilter />
          {/* <Testing /> */}

          <AboutUs />
          <Faqs faqs={Page?.topFAQs} />
          <Contact socialLinks={Page?.websetting?.socialLinks} />
          <Footer />
        </>
      )}
    </>
  );
}
