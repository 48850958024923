import React from 'react';
import { Button, HStack, Stack } from '@chakra-ui/react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const maxPageButtons = 10; // Maximum number of page buttons to display

  const startPage = Math.max(
    1,
    Math.min(currentPage - Math.floor(maxPageButtons / 2), totalPages - maxPageButtons + 1)
  );
  const endPage = Math.min(totalPages, startPage + maxPageButtons - 1);

  return (
    <Stack >
        <HStack spacing={2} justifyContent="center" mt={8}>
      {/* Previous Button */}
      {currentPage > 1 && (
        <Button onClick={() => onPageChange(currentPage - 1)}>
          Previous
        </Button>
      )}

      {/* Page Buttons */}
      {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
        <Button
          key={startPage + index}
          onClick={() => onPageChange(startPage + index)}
          variant={currentPage === startPage + index ? 'solid' : 'outline'}
        >
          {startPage + index}
        </Button>
      ))}

      {/* Next Button */}
      {currentPage < totalPages && (
        <Button onClick={() => onPageChange(currentPage + 1)}>
          Next
        </Button>
      )}
    </HStack>
    </Stack>
  );
};

export default Pagination;
