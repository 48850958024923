import {
  Container,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
} from "@chakra-ui/react";
 
import React, { useEffect, useState } from 'react'
import Header from '../../components/Website/Header';
import SmBanner from '../../components/Website/Herobanner/SmBanner'

import Footer from '../../components/Website/Footer';
import { GET } from "../../utilities/ApiProvider";


export default function FaqsDetails() {

  const [data, setData] = useState(null);

    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth', // Add smooth scrolling behavior
        });
      }, []);
      useEffect(() => {
        getFAQs();
      }, []);
      const getFAQs = async () => {
        try {
          const response = await GET("/faq/");
          console.log(response);
          if (response?.statusCode === 200) {
            setData(response?.data);
          } else {
            setData([]);
          }
        } catch (error) {
          console.log(error);
        }
      };

  return (
    <>
    <Header />
    <SmBanner banheading={'Faqs'}/>
    <Stack id="faqs" py={20}>
        <Container py={4} maxW={{ base: "8xl", xl: "6xl", "2xl": "8xl" }}>
          <Stack pb={20} textAlign={"center"} gap={6}>
            <Heading as={"h4"} fontSize={"20px"} color={"#784FCF"}>
              FAQ’s
            </Heading>
            <Heading
              as={"h2"}
              fontSize={{ base: "25px", md: "30px", xl: "35px", "2xl": "35px" }}
              color={"#111"}
            >
              Frequently Ask Questions
            </Heading>
          </Stack>
          <SimpleGrid
            alignItems={"center"}
            columns={{ base: 1, md: 1, xl: 1 }}
            spacing={10}
          >
           
            <Stack>
              <Accordion defaultIndex={[0]} allowMultiple>
                {data?.length > 0 &&
                  data.map((v, k) => {
                    return (
                      <AccordionItem  key={k} mb={2} border={"none"}>
                        <h2>
                          <AccordionButton
                          boxShadow='base'
                          mb={2}
                          py={4}
                            bg={"#f2f9f5"}
                            borderRadius={8}
                            _expanded={{ bg: "#784FCF", color: "white" }}
                          >
                            <Box as="span" flex="1" textAlign="left">
                              <Heading fontSize={"16px"} noOfLines={2}>
                                {v?.question}
                              </Heading>
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel p={0}      pb={4}>
                          <Text borderRadius={6} py={4} px={6} boxShadow='base'  fontSize={'15px'} fontWeight={500}> {v?.answer}</Text>
                        </AccordionPanel>
                      </AccordionItem>
                    );
                  })}
              </Accordion>
            </Stack>
          </SimpleGrid>
        </Container>
      </Stack>
    <Footer/>
    </>
  )
}
