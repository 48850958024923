import {
  Container,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import faqleft from "../../../assets/images/faqs.png";
import { GET } from "../../../utilities/ApiProvider";

export default function TermsConditions() {
  const [Terms, setTerms] = useState("");

  // --------------------get Terms---------------------
  const getTerms = async () => {
    try {
      const response = await GET("/pages/terms");
      console.log(response);
      if (response?.statusCode === 200) {
        setTerms(response?.data?.content);
      } else {
        setTerms([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getTerms();
  }, []);

  return (
    <>
      <Stack
        py={{
          base: 12,
          md: 6,
          xl: 8,
          "2xl": 20,
        }}
      >
        <Container py={4} maxW={{ base: "8xl", xl: "6xl", "2xl": "8xl" }}>
          <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} spacing={10}>
            <Stack gap={6}>
              <Stack className="termsText">
                <Text dangerouslySetInnerHTML={{ __html: Terms }} />
              </Stack>
            </Stack>
          </SimpleGrid>
        </Container>
      </Stack>
    </>
  );
}
