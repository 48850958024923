import React, { useEffect } from "react";
import Header from "../../components/Website/Header";
import SmBanner from "../../components/Website/Herobanner/SmBanner";
import Privacy from "../../components/Website/Privacy";
import Footer from "../../components/Website/Footer";
import TermsConditions from "../../components/Website/Terms";

export default function TermsServices() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Add smooth scrolling behavior
    });
  }, []);

  return (
    <>
      <Header  />
      <SmBanner banheading={"Terms of Service"} />
      {/* <Privacy/> */}
      <TermsConditions />
      <Footer />
    </>
  );
}
