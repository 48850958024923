import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  useBreakpointValue,
  Button,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { logout } from "../../../reducers/useReducers";
import { useDispatch } from "react-redux";

function DashNavbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedService, setSelectedService] = useState("");
  const navItems = [
    { name: "Home", url: "/dashboard" },
    { name: "All Day Care's", url: "/dashboard/daycares" },
    { name: "FAQ's", url: "/dashboard/faqs" },
    { name: "Contact Queries", url: "/dashboard/Contact-Queries" },
    { name: "My Account", url: "/dashboard/account" },
    { name: "Web Policy", url: "/dashboard/update-Privacy-Policy" },
  ];
  useEffect(() => {
    const findService = navItems.find((item) => item.url === location.pathname);
    setSelectedService(findService?.name);
  }, [location]);

  const handleNavClick = (name, url) => {
    setSelectedService(name);
    localStorage.setItem("selectedService", name);
    navigate(url);
  };

  return (
    <Flex
      color={useColorModeValue("gray.600", "white")}
      minH={"60px"}
      py={{ base: 2 }}
      px={{ base: 4 }}
      // borderStyle={"solid"}
      // border={"1px solid"}
      align={"center"}
    >
      <Flex
        flex={{ base: 1, md: "auto" }}
        ml={{ base: -2 }}
        display={{ base: "flex", md: "none" }}
      ></Flex>
      <Flex
        flex={{ base: 1 }}
        align={"center"}
        justifyContent={"space-between"}
      >
        <Text
          textAlign={useBreakpointValue({ base: "center", md: "left" })}
          fontFamily={"heading"}
          color={useColorModeValue("gray.800", "white")}
          textTransform={"uppercase"}
          fontWeight={700}
          fontSize={"30px"}
          cursor={"pointer"}
          onClick={() => navigate("/dashboard")}
        >
          The Little Hub
        </Text>

        <Flex
          gap={{ base: "10px", md: "20px" }}
          display={{ base: "none", md: "flex" }}
          ml={10}
          alignItems={"center"}
        >
          {navItems.map((item, index) => (
            <Box
              key={index}
              borderRadius={"5.5px"}
              border={"1px solid #E4E4E4"}
              p={"10px 15px"}
              w="max-content"
              bg={selectedService === item?.name ? "#784FCF" : "#E4E4E4"}
              cursor={"pointer"}
              color={selectedService === item?.name ? "white" : "black"}
              onClick={() => handleNavClick(item?.name, item?.url)}
            >
              {item?.name}
            </Box>
          ))}
          <Button
            borderRadius={"5.5px"}
            border={"1px solid #E4E4E4"}
            p={"22px 15px"}
            w="max-content"
            fontWeight={300}
            bg={"#E4E4E4"}
            color={"black"}
            _hover={{
              bg: "#784FCF",
              color: "white",
            }}
            onClick={() => {
              dispatch(logout());

              navigate("/login");
            }}
          >
            Logout
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default DashNavbar;
