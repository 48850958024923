import { Image, Stack, Text } from "@chakra-ui/react";
import React from "react";
import daycare from "../../../assets/images/daycare.png";
import { IoClose } from "react-icons/io5";

function details({ location, handleClose }) {
  console.log(location);
  return (
    <Stack direction={"column"} height={"94vh"} px={4}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={4}
        mb={2}
      >
        <Text mx={4} textAlign={"center"} fontWeight={700} fontSize={"22px"}>
          Daycare Details
        </Text>
        <IoClose
          cursor={"pointer"}
          fontSize={30}
          onClick={() => handleClose()}
        />
      </Stack>
      <Image src={daycare} />
      <Text color="#F39F5F" fontSize="16px" fontWeight="400">
        {location?.licensed ? "Licensed" : "Not Licensed"}
      </Text>
      <Text color="#385469" fontSize="20px" fontWeight="600">
        {location?.name}
      </Text>
      <Text color="#385469" fontSize="15px" fontWeight="400">
        {location?.description}
      </Text>

      {/* <h1>{location.name}</h1>
      <p>{location.description}</p>
      <p>
        {location.city}, {location.postalCode}
      </p>
      <p>Capacity: {location.capacity}</p> */}
    </Stack>
  );
}

export default details;
