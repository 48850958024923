import { Box, Image, Stack } from "@chakra-ui/react";

import authBg from "../../assets/images/logo.png";
import NewPassword from "./NewPassword";
export default function ChangePassword() {
  return (
    <Stack
      direction={{ base: "column", lg: "row" }}
      height={"100vh"}
      spacing={0}
    >
      <Box
        display={{ base: "none", lg: "block" }}
        bgImage={`url(${authBg})`}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
        w={{ lg: "405px", xl: "505px", xxl: "605px" }}
      >
        <Image src={authBg} w="100%" h="100%" />
      </Box>
      <NewPassword />
    </Stack>
  );
}
