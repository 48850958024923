// import {
//   Button,
//   Container,
//   Heading,
//   Image,
//   SimpleGrid,
//   Stack,
//   Text,
//   List,
//   ListItem,
//   ListIcon,
//   OrderedList,
//   UnorderedList,
// } from "@chakra-ui/react";
// import React from "react";
// import Aboutr from "../../../assets/images/abr.jpg";
// import Aboutr2 from "../../../assets/images/abr2.jpg";
// import { Link } from "react-router-dom";

// export default function AboutDetail() {
//   return (
//     <Stack id="aboutus" py={{
//       base: 12,
//       md: 6,
//       xl: 8,
//       "2xl": 20,
//     }} gap={12}>
      
//       <Container py={4} maxW={{ base: "8xl", xl: "6xl", "2xl": "8xl" }}>
//         <SimpleGrid alignItems={'center'} columns={2} gap={16}  >
//           <Stack gap={6}>
//             <Heading as={"h4"} fontSize={"20px"} color={"#111"}>
//               Our Platform
//             </Heading>
//             <Heading
//               as={"h2"}
//               fontSize={{
//                 base: "25px",
//                 md: "30px",
//                 xl: "30px",
//                 "2xl": "30px",
//               }}
//               color={"#111"}
//             >
//               How We Help Families and Providers
//             </Heading>
//             <Text fontSize={"16px"} color={"#111"}>
//               The Little Hub offers a comprehensive suite of features that
//               benefit both parents and daycare providers. Our platform provides:
//             </Text>
//             <UnorderedList>
//               <ListItem>
//                 <Text fontSize={"16px"} color={"#111"}>
//                   <strong>AI-Powered Daycare Locator : </strong> Parents can
//                   search for daycares based on specific criteria, such as
//                   location, budget, operating hours, capacity, and educational
//                   philosophy. Using predictive analytics, we recommend the best
//                   daycare options to match family needs.
//                 </Text>
//               </ListItem>
//               <ListItem>
//                 <Text fontSize={"16px"} color={"#111"}>
//                   <strong>Vacancy Management : </strong> Daycare providers can
//                   list temporary vacancies in real-time, allowing parents to
//                   book short-term daycare services. This feature ensures that
//                   daycares fully utilize their available spots while providing
//                   parents with flexible options.
//                 </Text>
//               </ListItem>
//               <ListItem>
//                 <Text fontSize={"16px"} color={"#111"}>
//                   <strong>Advanced Analytics for Daycare Providers : </strong>{" "}
//                   We equip daycare businesses with the insights they need to
//                   optimize their operations. From monitoring enrollment trends
//                   to analyzing parent reviews, our platform helps daycare
//                   centers improve service quality and maximize occupancy.
//                 </Text>
//               </ListItem>
//               <ListItem>
//                 <Text fontSize={"16px"} color={"#111"}>
//                   <strong>Corporate Daycare Solutions : </strong> We partner
//                   with organizations and co-working spaces to develop corporate
//                   daycare setups, helping companies offer reliable on-site care
//                   options for working parents. This service allows organizations
//                   to better support their employees while maximizing daycare
//                   capacity for the broader community.
//                 </Text>
//               </ListItem>
//             </UnorderedList>
//             <Heading as={"h4"} fontSize={"20px"} color={"#111"}>
//               Addressing Key Challenges in Canadian Daycare
//             </Heading>
//             <Text fontSize={"16px"} color={"#111"}>
//               In 2022, nearly 57% of parents reported struggling to find daycare
//               in their neighborhoods, and 46% said affordability was a
//               significant issue. Many parents had to adjust their work schedules
//               or reduce their hours due to the lack of available childcare
//               options. The Little Hub is designed to address these problems
//               head-on by providing tools that enhance accessibility, increase
//               affordability, and improve the overall quality of daycare services
//               across Canada.
//             </Text>
//           </Stack>
//           <Stack>
//               <Image borderRadius={8} src={Aboutr} alt="Aboutus" />
//             </Stack>
//             <Stack>
//               <Image borderRadius={8} src={Aboutr2} alt="Aboutus" />
//             </Stack>
//           <Stack gap={6}>
//             <Heading as={"h4"} fontSize={"20px"} color={"#111"}>
//               Our Vision for the Future
//             </Heading>
//             <Heading
//               as={"h2"}
//               fontSize={{
//                 base: "25px",
//                 md: "30px",
//                 xl: "30px",
//                 "2xl": "30px",
//               }}
//               color={"#111"}
//             >
//               How We Help Families and Providers
//             </Heading>
//             <Text fontSize={"16px"} color={"#111"}>
//               We envision a future where finding daycare is no longer a
//               stressful, time-consuming process. By leveraging technology and
//               data-driven solutions, The Little Hub seeks to empower both
//               parents and daycare providers, ensuring that every child in Canada
//               has access to high-quality care, and every daycare business has
//               the tools needed to thrive.
//             </Text>
//             <Text fontSize={"16px"} color={"#111"}>
//               The Little Hub will continue to innovate and expand its services,
//               ensuring that we remain at the forefront of the daycare industry
//               in Canada. Whether it’s creating more daycare spaces, offering
//               flexible booking options, or helping daycare providers optimize
//               their operations, we are committed to making a positive impact on
//               Canadian families and communities.
//             </Text>

//             <Heading as={"h4"} fontSize={"20px"} color={"#111"}>
//               Our Commitment to Canadian Families
//             </Heading>
//             <Text fontSize={"16px"} color={"#111"}>
//               Our commitment extends beyond just connecting families with
//               daycares. We are actively working to improve the daycare landscape
//               across Canada by:
//             </Text>
//             <UnorderedList>
//               <ListItem>
//                 <Text fontSize={"16px"} color={"#111"}>
//                   <strong>Increasing Daycare Accessibility : </strong>We’re
//                   mapping daycare locations nationwide to make them easily
//                   searchable, allowing parents to find the best options with
//                   just a click.
//                 </Text>
//               </ListItem>
//               <ListItem>
//                 <Text fontSize={"16px"} color={"#111"}>
//                   <strong>Supporting Daycare Providers : </strong> We help
//                   daycare centers modernize their operations, increasing their
//                   efficiency and ability to serve more families.
//                 </Text>
//               </ListItem>
//               <ListItem>
//                 <Text fontSize={"16px"} color={"#111"}>
//                   <strong>Enhancing Quality of Care : </strong>{" "}
//                   Through user feedback and analytics, we ensure that daycare providers can continuously improve the services they offer to children.
//                 </Text>
//               </ListItem>
//             </UnorderedList>
//             <Text fontSize={"16px"} color={"#111"}>
//               We’re not just a platform; we’re a movement. The Little Hub is
//               dedicated to creating solutions that work for both families and
//               providers, making daycare in Canada more accessible, affordable,
//               and world-class.
//             </Text>
//           </Stack>
//         </SimpleGrid>
//       </Container>
//     </Stack>
//   );
// }


import {
  Button,
  Container,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import Aboutr from "../../../assets/images/abr.jpg";
import Aboutr2 from "../../../assets/images/abr2.jpg";
import { Link } from "react-router-dom";

export default function AboutDetail() {
  return (
    <Stack id="aboutus" py={{
      base: 12,
      md: 6,
      xl: 8,
      "2xl": 20,
    }} gap={12}>
      
      <Container py={4} maxW={{ base: "8xl", xl: "6xl", "2xl": "8xl" }}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={12} mb={12} align="center">
          {/* Centered Image on the left */}
          <Image
            borderRadius="lg"
            src={Aboutr}
            alt="About us"
            order={1}
            mx="auto" // Centers the image horizontally within its column
          />

          {/* Text content on the right */}
          <Stack spacing={6} order={2}>
            <Heading as="h4" fontSize="20px" color="gray.800">
              Our Platform
            </Heading>
            <Heading as="h2" fontSize={{ base: "25px", md: "30px" }} color="gray.800">
              How We Help Families and Providers
            </Heading>
            <Text fontSize="16px" color="gray.700">
              The Little Hub offers a comprehensive suite of features that
              benefit both parents and daycare providers. Our platform provides:
            </Text>
            <UnorderedList spacing={3} fontSize="16px" color="gray.700">
              <ListItem><strong>AI-Powered Daycare Locator:</strong> Parents can search for daycares based on criteria like location, budget, and operating hours. Predictive analytics help recommend the best options to meet family needs.</ListItem>
              <ListItem><strong>Vacancy Management:</strong> Providers can list temporary vacancies in real-time, allowing parents to book short-term services, ensuring full utilization of available spots.</ListItem>
              <ListItem><strong>Advanced Analytics for Daycare Providers:</strong> Insights on operations help providers optimize service quality and occupancy.</ListItem>
              <ListItem><strong>Corporate Daycare Solutions:</strong> We collaborate with organizations to set up on-site daycare, providing reliable care options for working parents.</ListItem>
            </UnorderedList>
            <Heading as="h4" fontSize="20px" color="gray.800">
              Addressing Key Challenges in Canadian Daycare
            </Heading>
            <Text fontSize="16px" color="gray.700">
              In 2022, nearly 57% of parents struggled to find daycare nearby, with 46% facing affordability issues. The Little Hub addresses these problems by enhancing accessibility, affordability, and quality across Canada.
            </Text>
          </Stack>
        </SimpleGrid>

        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={12} mb={12} align="center">
          {/* Centered Image on the left */}
          <Image
            borderRadius="lg"
            src={Aboutr2}
            alt="About us"
            order={{ base: 1, md: 2 }}
            m="auto" // Centers the image horizontally within its column
          />

          {/* Text content on the right */}
          <Stack spacing={6} order={{ base: 2, md: 1 }}>
          <Heading as={"h4"} fontSize={"20px"} color={"#111"}>
              Our Vision for the Future
            </Heading>
            <Heading
              as={"h2"}
              fontSize={{
                base: "25px",
                md: "30px",
                xl: "30px",
                "2xl": "30px",
              }}
              color={"#111"}
            >
              How We Help Families and Providers
            </Heading>
            <Text fontSize={"16px"} color={"#111"}>
              We envision a future where finding daycare is no longer a
              stressful, time-consuming process. By leveraging technology and
              data-driven solutions, The Little Hub seeks to empower both
              parents and daycare providers, ensuring that every child in Canada
              has access to high-quality care, and every daycare business has
              the tools needed to thrive.
            </Text>
            <Text fontSize={"16px"} color={"#111"}>
              The Little Hub will continue to innovate and expand its services,
              ensuring that we remain at the forefront of the daycare industry
              in Canada. Whether it’s creating more daycare spaces, offering
              flexible booking options, or helping daycare providers optimize
              their operations, we are committed to making a positive impact on
              Canadian families and communities.
            </Text>

            <Heading as={"h4"} fontSize={"20px"} color={"#111"}>
              Our Commitment to Canadian Families
            </Heading>
            <Text fontSize={"16px"} color={"#111"}>
              Our commitment extends beyond just connecting families with
              daycares. We are actively working to improve the daycare landscape
              across Canada by:
            </Text>
            <UnorderedList>
              <ListItem>
                <Text fontSize={"16px"} color={"#111"}>
                  <strong>Increasing Daycare Accessibility : </strong>We’re
                  mapping daycare locations nationwide to make them easily
                  searchable, allowing parents to find the best options with
                  just a click.
                </Text>
              </ListItem>
              <ListItem>
                <Text fontSize={"16px"} color={"#111"}>
                  <strong>Supporting Daycare Providers : </strong> We help
                  daycare centers modernize their operations, increasing their
                  efficiency and ability to serve more families.
                </Text>
              </ListItem>
              <ListItem>
                <Text fontSize={"16px"} color={"#111"}>
                  <strong>Enhancing Quality of Care : </strong>{" "}
                  Through user feedback and analytics, we ensure that daycare providers can continuously improve the services they offer to children.
                </Text>
              </ListItem>
            </UnorderedList>
            <Text fontSize={"16px"} color={"#111"}>
              We’re not just a platform; we’re a movement. The Little Hub is
              dedicated to creating solutions that work for both families and
              providers, making daycare in Canada more accessible, affordable,
              and world-class.
            </Text>
          </Stack>
        </SimpleGrid>
      </Container>
    </Stack>
  );
}