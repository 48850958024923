import React from "react";
import { dotSpinner } from "ldrs";
import { Stack } from "@chakra-ui/react";
dotSpinner.register();

function Loader() {
  return (
    <Stack
      w={"100%"}
      h={"calc(100vh - 100px)"}
      flexDirection={"row"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <l-dot-spinner size="60" speed="0.9" color="#784FCF"></l-dot-spinner>
    </Stack>
  );
}

export default Loader;
