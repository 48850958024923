import {
  Container,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import faqleft from "../../../assets/images/faqs.png";
import { GET } from "../../../utilities/ApiProvider";

export default function Privacy() {
  const [Privacy, setPrivacy] = useState("");

  // --------------------get privacy---------------------
  const getPrivacy = async () => {
    try {
      const response = await GET("/pages/privacy");
      console.log(response);
      if (response?.statusCode === 200) {
        setPrivacy(response?.data?.content);
      } else {
        setPrivacy([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPrivacy();
  }, []);

  return (
    <>
      <Stack
        py={{
          base: 12,
          md: 6,
          xl: 8,
          "2xl": 20,
        }}
      >
        <Container py={4} maxW={{ base: "8xl", xl: "6xl", "2xl": "8xl" }}>
          <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} spacing={10}>
            <Stack gap={6}>
              <Stack gap={4}>
                {/* <Heading
                  fontSize={{
                    base: "20px",
                    md: "30px",
                    xl: "30px",
                    "2xl": "30px",
                  }}
                >
                  Duis aute irure dolor in reprehenderit 
                </Heading> */}
                <Stack className="termsText">
                  <Text
                    color={"#5C5C5C"}
                    dangerouslySetInnerHTML={{ __html: Privacy }}
                  />
                </Stack>
              </Stack>
            </Stack>
          </SimpleGrid>
        </Container>
      </Stack>
    </>
  );
}
