import React, { useEffect, useState } from "react";
import CustHeading from "../../components/Dashboard/Heading/CustHeading";
import {
  Box,
  Heading,
  HStack,
  Icon,
  Image,
  SimpleGrid,
  Stack,
  Text,
  Button,
  Flex,
} from "@chakra-ui/react";
import { IoMailOutline } from "react-icons/io5";
import { GET } from "../../utilities/ApiProvider";
import Profilepic from "../../assets/images/profile.png";
import { Link } from "react-router-dom";
import Loader from "../../components/Dashboard/Loader/Loader";
import Pagination from "../../components/Dashboard/Pagination";

export default function ContactQueries() {
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9; // Items to show per page

  const getAllDayCares = async () => {
    try {
      const response = await GET("/contact");
      if (response?.success === true) {
        setData(response?.data);
      } else {
        setData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllDayCares();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Calculate paginated data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(data?.length / itemsPerPage);

  return (
    <>
      <CustHeading
        name={"Contact Queries"}
        subHeading={"Here is the contact queries list...Check Now!"}
      />
      {!data ? (
        <Loader />
      ) : data?.length === 0 ? (
        <Stack>
          <Text px={4} fontSize={"20px"} fontWeight={"500"}>
            No Contact Queries Found
          </Text>
        </Stack>
      ) : (
        <Stack>
          <SimpleGrid
            columns={{ base: 1, md: 2, xl: 2, "2xl": 3 }}
            spacing={10}
          >
            {currentItems?.length > 0 &&
              currentItems.map((v, k) => {
                return (
                  <Box
                    key={k}
                    bg={"#EBEBEB"}
                    p={5}
                    borderRadius={"12px"}
                    shadow={"md"}
                  >
                    <Stack
                      flexDirection={{
                        base: "column",
                        md: "column",
                        xl: "row",
                        "2xl": "row",
                      }}
                      justifyContent={"space-between"}
                      alignItems={{
                        base: "start",
                        md: "start",
                        xl: "center",
                        "2xl": "center",
                      }}
                    >
                      <HStack gap={4}>
                        <Box w="60px" h="60px">
                          <Image borderRadius={"8px"} src={Profilepic} />
                        </Box>
                        <Box>
                          <Heading fontSize={"18px"} fontWeight={"bolder"}>
                            {v?.firstname} {v?.lastname}
                          </Heading>
                          <Stack
                            flexDirection="row"
                            mt={1}
                            alignItems="center"
                            spacing={2}
                          >
                            <Icon as={IoMailOutline} />
                            <Text
                              marginLeft={"-5px"}
                              fontSize="14px"
                              color={"black"}
                              fontWeight={600}
                            >
                              {v?.email}
                            </Text>
                          </Stack>
                        </Box>
                      </HStack>
                      <Box>
                        <Button
                          as={Link}
                          to={`/dashboard/Contact-Details/${v?._id}`}
                          bg={"#EBEBEB"}
                          px={{ base: 2, lg: 4 }}
                          border={"1px solid"}
                        >
                          View Details
                        </Button>
                      </Box>
                    </Stack>
                    <Flex justifyContent={"space-between"}>
                      <Text
                        fontSize={"16px"}
                        mt={4}
                        fontWeight={"500"}
                        color={"#75767A"}
                      >
                        <Text fontWeight={700} as={"span"}>
                          Added Date:
                        </Text>{" "}
                        {new Date(v?.createdAt).toLocaleDateString()}
                      </Text>
                      <Text
                        fontSize={"16px"}
                        mt={4}
                        fontWeight={"500"}
                        color={"#75767A"}
                      >
                        <Text fontWeight={700} as={"span"}>
                          Phone :
                        </Text>{" "}
                        {v?.number}
                      </Text>
                    </Flex>
                  </Box>
                );
              })}
          </SimpleGrid>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </Stack>
      )}
    </>
  );
}
