import React, { useEffect, useState } from "react";
import CustHeading from "../../components/Dashboard/Heading/CustHeading";
import {
  SimpleGrid,
  Stack,
  Button,
  Flex,
  Box,
  Text,
  Image,
  Heading,
  Icon,
  useToast,
} from "@chakra-ui/react";
import { RiEditLine } from "react-icons/ri";
import { MdDeleteOutline } from "react-icons/md";
import Dpic from "../../assets/images/notfount.png";
import { LuDollarSign } from "react-icons/lu";
import { useNavigate, useParams } from "react-router-dom";
import { DELETE, GET } from "../../utilities/ApiProvider";
import Loader from "../../components/Dashboard/Loader/Loader";
import { imgUrl } from "../../utilities/config";
import { PiCity } from "react-icons/pi";
import { MdListAlt } from "react-icons/md";
import { BsSignpost2 } from "react-icons/bs";
import { IoLocationOutline } from "react-icons/io5";
import { LuUsers2, LuEye } from "react-icons/lu";
import { IoArrowBackSharp } from "react-icons/io5";

export default function DaycareDetails() {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const toast = useToast();
  const getData = async () => {
    try {
      const response = await GET(`/daycare/${id}`);
      console.log(response);
      if (response?.statusCode === 200) {
        setData(response?.data);
      } else {
        setData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const handleRemove = async () => {
    try {
      const response = await DELETE(`/daycare/${id}`);
      console.log(response);
      if (response?.statusCode === 200) {
        navigate("/dashboard/daycares");
        toast({
          title: "Daycare Deleted Successfully",
          status: "success",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
      } else {
        setData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack>
          <Button
            onClick={() => navigate("/dashboard/daycares")}
            color={"#fff"}
            bg={"#2E8B44"}
            w={"max-content"}
            textTransform={"uppercase"}
            fontSize={"14px"}
            _hover={{
              bg: "#2E8B44",
            }}
          >
            <Icon mr={2} fontSize={"20px"} as={IoArrowBackSharp} /> Back
          </Button>
          <CustHeading
            name={"Day Care Details"}
            subHeading={"Here is the details of daycare...Check Now!"}
          />
        </Stack>

        <Flex gap={6} alignItems={"center"}>
          <Button
            bg={"#784FCF"}
            color={"#fff"}
            padding={"22px 25px"}
            w={"max-content"}
            fontSize={"17px"}
            borderRadius={"10px"}
            onClick={() =>
              navigate("/dashboard/add-new-daycare", {
                state: { editData: data },
              })
            }
          >
            <Icon as={RiEditLine} />
          </Button>
          <Button
            bg={"#E23D40"}
            color={"#fff"}
            padding={"22px 30px"}
            w={"max-content"}
            fontSize={"17px"}
            borderRadius={"10px"}
            onClick={handleRemove}
          >
            <Icon as={MdDeleteOutline} />
          </Button>
        </Flex>
      </Box>
      {!data ? (
        <Loader />
      ) : (
        <SimpleGrid columns={2}>
          <Stack gap={4}>
            <Image
              w={"543px"}
              height={"374px"}
              src={
                !data?.image || data?.image === "null"
                  ? Dpic
                  : `${imgUrl}${data?.image}`
              }
            />

            <Flex alignItems={"center"} gap={2}>
              <Icon fontSize={"19px"} as={MdListAlt} />
              <Text color={"#803CCF"} fontWeight={700}>
                {data?.licensed ? "Licensed" : "Unlicensed"}
              </Text>
            </Flex>
            <Heading fontSize={"30px"} fontWeight={600}>
              {data?.NAME || data?.name}
            </Heading>
            <Text fontSize={"15px"} fontWeight={500}>
              {data?.DESCRIPTION ||
                data?.description ||
                ` Suspendisse non blandit sapien Nunc eleifend, enim et porta porta
            eros risus tincidunt diam, vel sodales. Suspendisse non blandit
   
            
            sapien Nunc eleifend, enim et porta porta eros risus tincidunt diam,
          
            
            vel sodales.`}
            </Text>
            <Stack direction={"row"} alignItems={"center"}>
              <Icon as={IoLocationOutline} />
              <Text
                fontSize={"15px"}
                fontWeight={500}
                textTransform={"capitalize"}
              >
                <Text
                  as={"span"}
                  color={"#803CCF"}
                  fontSize={"17px"}
                  fontWeight={700}
                >
                  Address :
                </Text>{" "}
                {data?.address || data?.address || "Address Details"}
              </Text>
            </Stack>
            <Stack direction={"row"} alignItems={"center"}>
              <Icon as={PiCity} />
              <Text
                fontSize={"15px"}
                fontWeight={500}
                textTransform={"capitalize"}
              >
                <Text
                  as={"span"}
                  color={"#803CCF"}
                  fontSize={"17px"}
                  fontWeight={700}
                >
                  City :
                </Text>{" "}
                {data?.city || data?.city || "City Name"}
              </Text>
            </Stack>
            <Stack direction={"row"} alignItems={"center"}>
              <Icon as={BsSignpost2} />
              <Text
                fontSize={"15px"}
                fontWeight={500}
                textTransform={"capitalize"}
              >
                <Text
                  as={"span"}
                  color={"#803CCF"}
                  fontSize={"17px"}
                  fontWeight={700}
                >
                  Postal Code :
                </Text>{" "}
                {data?.postalCode || data?.postalCode || "Postal Code"}
              </Text>
            </Stack>
          </Stack>
          <Stack gap={4}>
            <Heading fontSize={"22px"} fontWeight={500}>
              Analytics
            </Heading>
            <SimpleGrid columns={2} spacing={3}>
              {/* <Stack borderRadius={6} gap={8} padding={5} bg={"#EBEBEB"}>
                <Flex gap={4} alignItems={"center"}>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    bg={"#784FCF"}
                    w={"40px"}
                    height={"40px"}
                    borderRadius={"50%"}
                  >
                    <Icon color={"#fff"} fontSize={"19px"} as={LuDollarSign} />
                  </Box>

                  <Text
                    fontWeight={600}
                    fontSize={{ base: "14px", lg: "15px", "2xl": "18px" }}
                  >
                    Price Details
                  </Text>
                </Flex>
                <Heading fontSize={"25px"}>{data?.price}</Heading>
              </Stack> */}
              <Stack borderRadius={6} gap={8} padding={5} bg={"#EBEBEB"}>
                <Flex gap={4} alignItems={"center"}>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    bg={"#784FCF"}
                    w={"40px"}
                    height={"40px"}
                    borderRadius={"50%"}
                  >
                    <Icon color={"#fff"} fontSize={"19px"} as={LuUsers2} />
                  </Box>

                  <Text
                    fontWeight={600}
                    fontSize={{ base: "14px", lg: "15px", "2xl": "18px" }}
                  >
                    Total Capacity
                  </Text>
                </Flex>
                <Heading fontSize={"25px"}>{data?.capacity}</Heading>
              </Stack>
              <Stack borderRadius={6} gap={8} padding={5} bg={"#EBEBEB"}>
                <Flex gap={4} alignItems={"center"}>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    bg={"#784FCF"}
                    w={"40px"}
                    height={"40px"}
                    borderRadius={"50%"}
                  >
                    <Icon color={"#fff"} fontSize={"19px"} as={LuEye} />
                  </Box>

                  <Text
                    fontWeight={600}
                    fontSize={{ base: "14px", lg: "15px", "2xl": "18px" }}
                  >
                    Total Clicks
                  </Text>
                </Flex>
                <Heading fontSize={"25px"}>{data?.clicks}</Heading>
              </Stack>
            </SimpleGrid>
          </Stack>
        </SimpleGrid>
      )}
    </>
  );
}
